import React, {useEffect, useState, useContext} from 'react';
import { styled } from 'styled-components';
import HeaderDopBox from './parts/HeaderDopBox';
import useWindowSize from "./hooks/windowResize";
import scrollFunction from '../utils/scrollFunction';

import { LanguageContext } from '../context/languageContext';
import { translations } from '../translations';


const HeaderComponent = () => {
  const [windowWidth] = useWindowSize();
  const [showDopBox, setShowDopBox] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const { language } = useContext(LanguageContext);
  const text = translations.header[language];


  useEffect(() => {
    if(windowWidth < 768){
      setShowDopBox(false);
    } else {
      setShowDopBox(true);
    }

    if(windowWidth < 1200){
      setMobileMenu(true);
    } else {
      setMobileMenu(false);
    }
  }, [windowWidth]);

  const handleScroll = (id) => {
    scrollFunction(id, {
      windowWidth,
      menuOpen,
      setMenuOpen,
    });
  };
    
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const logoUrl = language === 'en' ? '/' : `/${language}/`;

  return (
    <Header>
      <div className="container">
        <div className="columns g-box">
          <div className="logo-box">
            <a href={logoUrl}>
              <img src="/img/logo.svg" alt="Meny-logo" />
            </a>
          </div>
          <MenuBox className={`menu-box g-box ${menuOpen ? 'active' : ''}`}>
            {mobileMenu && (
              <div className="menu-top-bar f-box">
                <div className="logo-box">
                  <a href="/">
                    <img src="/img/logo.svg" alt="Meny-logo" />
                  </a>
                </div>
                <CloseBt className="close-bt" onClick={() => toggleMenu()}>
                  <div className="wrapper">
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </CloseBt>
              </div>
            )}
            <nav>
              <ul className="f-box">
                <li>
                  <span onClick={() => handleScroll('about')}>{text?.menuItem1 || ''}</span>
                </li>
                <li>
                  <span onClick={() => handleScroll('how-to-buy')}>{text?.menuItem2 || ''}</span>
                </li>
                <li>
                  <span onClick={() => handleScroll('buy-sect')}>{text?.menuItem3 || ''}</span>
                </li>
                <li>
                  <span onClick={() => handleScroll('menynomics')}>{text?.menuItem4 || ''}</span>
                </li>
                <li>
                  <span onClick={() => handleScroll('roadmap')}>{text?.menuItem5 || ''}</span>
                </li>
                <li>
                  <span onClick={() => handleScroll('faq')}>{text?.menuItem6 || ''}</span>
                </li>
              </ul>
            </nav>
            {mobileMenu && <HeaderDopBox  setMenuOpen={setMenuOpen} />}
          </MenuBox>

          {showDopBox && <HeaderDopBox  setMenuOpen={setMenuOpen} />}

          {mobileMenu && (
            <MenuBt className="menu-bt" onClick={() => toggleMenu()}>
              <div className="wrapper">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </MenuBt>
          )}
        </div>
      </div>
    </Header>
  );
}

export default HeaderComponent;

const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-weight: 700;
  z-index: 999;

  .columns{
    grid-template-columns: auto 1fr auto;
    align-items: center;
    background-color: rgba(0, 98, 148, 0.5);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: var(--yellow-color);
    border-radius: 0px 0px 20px 20px;  
    padding: 16px;

    .logo-box{
      img{
        width: 185px;
      }
    }

    

    @media (max-width: 1200px) {
      justify-content: space-between;
      grid-template-columns: 1fr auto auto;
    }

    @media (max-width: 767px) {
      justify-content: space-between;
      grid-template-columns: 1fr auto;
      padding: 8px 12px;
    }
  }
`
const MenuBox = styled.div`
  &.menu-box{  
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;

    ul{
      gap: 0px 16px;
      padding: 0;
      margin: 0;
      list-style: none;
      justify-content: center;

      li a,
      li span{
        color: #fff;
        text-decoration: none;
        padding:4px 0;
        cursor: pointer;

        &:hover{
          color: var(--yellow-color)
        }
      }
    }

    .menu-top-bar{
      justify-content: space-between;
      align-items: center;
    }
  }
  @media (max-width: 1200px) {
    &.menu-box{
      width: 375px;
      height: 100vh;
      padding: 16px 24px 24px 24px;
      background: #006294;
      position: fixed;
      left: 100%;
      top: 0;
      z-index: 111;
      grid-gap: 50px;
      grid-template-rows: auto 1fr auto;
      align-items: flex-start;
      overflow-y: scroll;
      transition: transform .2s ease-in-out;
      transform: translateX(0); 
      font-size:16px;

      &.menu-box::-webkit-scrollbar { 
        width: 0; 
        display: none;
      }

      .dop-box{
        gap: 7px;
        justify-content: center;

        .btn{
          padding: 8px 15px 14px;
        }

        .select__control{
          min-width: 86px;
        }

        .social-list{
          grid-gap: 7px;
        }
      }

      &.active{
        transform: translateX(-100%); 
        transition-duration: .3s;
      }

      ul{
        grid-gap: 32px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: flex-start;

        li span{
          padding: 0;
        }
      }
    }
  }

  @media (max-width: 992px){
    &.menu-box{
      height: 100dvh;
    }
  }

  @media (max-width: 767px) {
    &.menu-box{
      padding-top: 8px;
    }
  }

  @media (max-width: 430px) {
    &.menu-box{
      width: 100vw;
    }
  }

  @media (max-width: 390px) {
    &.menu-box{
      .dop-box{
        justify-content: initial;
      }
    }
  }

`
const MenuBt = styled.div`
  &.menu-bt{
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    min-width: 42px;
    min-height: 48px;
    padding-bottom: 6px;
    position: relative;
    --box-shadow-color: var(--yellow-color-dark);
    box-shadow: 0px -6px 0px 0px var(--box-shadow-color) inset;
    border: 1px solid var(--yellow-color-dark);
    background-color: var(--yellow-color);
    cursor: pointer;

    &:hover{
        color: var(--black-color);
        --box-shadow-color: transparent;
    }

    &:active{
        box-shadow: 0px 6px 4px 0px #B09E00 inset;
    }

    .wrapper{
      position: relative;
      width: 100%;

      .line{
        width: 19px;
        height: 2px;
        background-color: var(--black-color);
        border-radius: 8px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &:nth-child(1){
          transform: translate(-50%, calc(-50% - 6px));
        }
        &:nth-child(3){
          transform: translate(-50%, calc(-50% + 6px));
        }

      }
    }

    &.active .box .wrapper .line:nth-child(1){
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &.active .box .wrapper .line:nth-child(2){
      width: 150%;
      opacity: 0;
    }
    &.active .box .wrapper .line:nth-child(3){
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
`
const CloseBt = styled.div`
  &.close-bt{
    color: #000;
    display: inline-flex;
    align-items: center;
    min-width: 42px;
    min-height: 48px;
    position: relative;
    cursor: pointer;

      .wrapper{
        position: relative;
        width: 100%;

        .line{
          width: 19px;
          height: 2px;
          background-color: var(--yellow-color);
          border-radius: 8px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          &:nth-child(1){
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:nth-child(2){
            transform: translate(-50%, -50%) rotate(-45deg);
          }

        }
      }
    }
  }
`