import React, {useState, useEffect, useContext} from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import useWindowSize from "../hooks/windowResize";
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const RoadMapSectionComponent = () => {
    const [windowWidth] = useWindowSize();
    const [mobArrow, setMobArrow] = useState(false);

    const { language } = useContext(LanguageContext);
    const text = translations.roadmapSect[language];

    useEffect(() => {
        if(windowWidth < 768){
            setMobArrow(true);
        } else {
            setMobArrow(false);
        }
    }, [windowWidth]);

    const [setRef] = useAnimateOnScroll('active');


    return (
        <RoadMapSection className="sect roadmap g-box with-shadow" id="roadmap">
            <div className="container">
                <div className="rows">
                    <div className="title-box slideUp" ref={setRef}>
                        <h3 className="title sect-title align-center">{text ? parse(text.title) : ''}</h3>
                    </div>
                    <div className="items-list g-box">
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="title-box">
                                <p className="title">{text ? parse(text.items[0].title) : ''}</p>
                            </div>
                            <div className="content">
                                {text ? parse(text.items[0].text) : ''}
                            </div>
                        </div>
                        <div className="arrow-box f-box slideUp" ref={setRef}>
                            <img src="/img/sections/roadmap/arrow-image.svg" alt="arrow" />
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="title-box">
                                <p className="title">{text ? parse(text.items[1].title) : ''}</p>
                            </div>
                            <div className="content">
                                {text ? parse(text.items[1].text) : ''}
                            </div>
                        </div>
                        <div className="arrow-box f-box slideUp" ref={setRef}>
                            <img src={mobArrow ? '/img/sections/roadmap/arrow-image.svg' : '/img/sections/roadmap/arrow-image-2.svg'} alt="arrow" />
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="title-box">
                                <p className="title">{text ? parse(text.items[2].title) : ''}</p>
                            </div>
                            <div className="content">
                                {text ? parse(text.items[2].text) : ''}
                            </div>
                        </div>
                        <div className="arrow-box f-box slideUp" ref={setRef}>
                            <img src="/img/sections/roadmap/arrow-image.svg" alt="arrow" />
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="title-box">
                                <p className="title">{text ? parse(text.items[3].title) : ''}</p>
                            </div>
                            <div className="content">
                                {text ? parse(text.items[3].text) : ''}
                            </div>
                        </div>
                    </div>
                    <div className="section-image slideUp" ref={setRef}>
                        <img src="/img/meny/meny-group.svg" alt="Meny" />
                    </div>
                </div>
            </div>
        </RoadMapSection>
    )
};
  
export default RoadMapSectionComponent;

const RoadMapSection = styled.div`
    &.roadmap{
        background-repeat: no-repeat;
        background-size: auto;
        background-position: bottom center;
        background-image: url(/img/sections/roadmap/section-bg.svg);
        padding-bottom: 0;
        position: relative;
        margin-bottom: 95px;
        padding-top: 0;

        &::after {
            content: '';
            width: 100%;
            height: 179px;
            background-image: url(/img/clouds/clouds.svg);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: center;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateY(105px);
            z-index: 3;
        }

        .rows{
            grid-gap: 30px;
        }

        .items-list{
            grid-template-columns: 1fr auto 1fr;
            grid-gap: 16px;
            margin-top: 50px;

            .arrow-box{
                max-width: 200px;

                img{
                    max-width: 100%;
                }
            }

            div:nth-child(4){
                grid-column-start: span 3;
                display: flex;
                justify-content: center;
                margin: 0 auto;

                img{                    
                    transform: rotate(-45deg);
                }
            }
            
            .item{
                position: relative;
                padding: 80px 78px 40px 50px;
                min-height: 372px;
                grid-gap: 16px;

                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;    
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: top;
                    background-image: url(/img/sections/roadmap/item-bg.png);
                }

                .title-box,
                .content{
                    position: relative;
                }

                .content{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;

                    ul{
                        display: grid;
                        grid-gap: 4px;
                        line-height: 150%;
                        margin: 0;
                        padding-left: 0;
                        list-style: none;

                        li{
                            padding-left: 30px;
                            position: relative;

                            &::before{
                                content: '';
                                width: 4px;
                                height: 4px;
                                top: 10px;
                                left: 15px;
                                border-radius: 50%;
                                background-color: var(--black-color);
                                position: absolute;
                            }
                        }
                    }
                }
            }
        }

        .section-image{
            margin-bottom:-40px;
        }
    
    }

    @media (max-width: 1440px){
        &.roadmap{
            .items-list{
                .arrow-box{
                    max-width: 13.9vw;
                }

                .item{
                    padding: 70px 50px 40px;
                }
            }
        }
    }

    @media (max-width: 992px){
        &.roadmap{
            .items-list{
                .arrow-box {
                    max-width: 11vw;
                }

                .item{
                    padding: 80px 25px 40px;

                    .title-box{
                        text-align: center;
                    }

                    .content{
                        ul li{
                            padding-left:25px;

                            &::before{
                                left:10px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px){
        &.roadmap{
            background-size: 100%;
            padding-top: 50px;
        
            margin-bottom: 17px;
            padding-bottom: 17px;

            &::after{
                transform: translateY(29px);
                height: 60px;
            }

            .items-list{
                grid-template-columns: 1fr;
                grid-gap: 32px;
                margin-top: 0;

                .item{
                    padding: 80px 30px 50px;

                    .content{
                        ul li{
                            padding-left:30px;

                            &::before{
                                left:15px;
                            }
                        }
                    }
                }

                div:nth-child(4){
                    grid-column-start: initial;
                }

                .arrow-box{
                    margin: 0 auto;
                    max-width: 100px;
                }

                .arrow-box img,
                div:nth-child(4) img{
                    transform: rotate(90deg);
                }
            }

            .section-image{
                margin-top: 17px;
                margin-bottom: 0;
            }
        }
    }
`