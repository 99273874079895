import React from 'react';
import { styled } from 'styled-components';
import SocialList from './ui/SocialList';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter className="g-box">
      <div className="container">
        <div className="footer-rows align-center g-box">
          <div>
            <p>©{currentYear} $MENY</p>
          </div>
          <SocialList telegram x instagram youtube/>
        </div>
      </div>
    </StyledFooter>
  );
}
  
export default Footer;

const StyledFooter = styled.footer`
  margin-top: -137px;
  position: relative;
  z-index: 4444;

  .footer-rows{
    border-radius: 16px 16px 0px 0px;
    border-top: 1px solid #FFCD00;
    border-right: 1px solid #FFCD00;
    border-left: 1px solid #FFCD00;
    background: rgba(0, 98, 148, 0.50);
    backdrop-filter: blur(5px);
    padding: 16px;
    grid-gap: 24px;
    justify-content: center;
    color: #FFF;
    font-size: 24px;
    font-weight: 900;
    line-height: 133.333%;

    .social-list{
      grid-gap:40px;
    
    }
  }

  @media (max-width: 768px){
    .footer-rows{
      .social-list{
        grid-gap: 32px;
      }
    }
  
  }
`