import { useEffect, useState, useCallback } from 'react';

const useAnimateOnScroll = (animationClass, threshold = 0.1) => {
  const [elementsRefs, setElementsRefs] = useState([]);

  const setRef = useCallback((node) => {
    setElementsRefs((prevRefs) => {
      if (node && !prevRefs.includes(node)) {
        return [...prevRefs, node];
      }
      return prevRefs;
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(animationClass);
          }
        });
      },
      { threshold }
    );

    elementsRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      elementsRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [elementsRefs, threshold, animationClass]);

  return [setRef];
};

export default useAnimateOnScroll;
