import React, {useContext} from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const DisclaimerSectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');

    const { language } = useContext(LanguageContext);
    const text = translations.disclaimerSect[language];

    return (
        <DisclaimerSection className="sect disclaimer g-box with-shadow">
            <div className="container">
                <div className="disclaimer-box g-box slideUp" ref={setRef}>
                    <div className="title-box">
                        <h3 className="title sect-title align-center">{text ? parse(text.title) : ''}</h3>
                    </div>
                    <div className="content-box">
                        {text ? parse(text.text) : ''}
                    </div>
                </div>
                <div className="image-box slideUp" ref={setRef}>
                    <img src="/img/meny/meny-4.svg" alt="Meny" />
                </div>
            </div>
            <div className="horn-box hornRight" ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
        </DisclaimerSection>
    )
};
  
export default DisclaimerSectionComponent;

const DisclaimerSection = styled.div`
    &.disclaimer{
        background-repeat: no-repeat;
        background-size: auto;
        background-position: bottom center;
        background-image: url(/img/sections/disclaimer/disclaimer-bg.svg);
        padding-bottom: 0;
        position: relative;

        .container{
            overflow: hidden;
        }

        .horn-box{
            width: 355px;
            position: absolute;
            bottom: 275px;
            right: 0;
            z-index: 3;
        }
    
        .disclaimer-box{
            border-radius: 40px;
            border: 2px solid var(--yellow-color);
            background: #FFF;
            box-shadow: -4px 4px 0px 0px #010101;
            padding: 24px;
            grid-gap: 24px;
            max-width: 848px;
            margin: 0 auto;

            .attention{
                text-transform: uppercase;
                color: var(--red-color);
            }

            .title-box .title{
                color: var(--red-color);
                text-shadow: -2px 4px 0px #424242;
            }

            .content-box{
                .red{
                    color: var(--red-color);
                }
            }
        }

        .image-box{
            display: flex;
            justify-content: center;
            margin-top: -28px;
            margin-bottom: 47px;

            img{
                max-width:457px;
            }
        }
        
    }

    @media (max-width: 1330px){
        &.disclaimer{
            .horn-box{
                width: 290px;
            }
        }
    }

    @media (max-width: 992px){
        &.disclaimer{
            .image-box{
                img{
                    max-width:50vw;
                }
            }

            .horn-box{
                width: 200px;
            }

            .title-box .title{
                word-break: break-all;
            }
        }
    }

    @media (max-width: 768px){
        &.disclaimer{
            .horn-box{
                display: none;
            }
            .image-box{
                margin-top: -20px;
                
                img{
                    max-width:100%;
                }
            }
        }
    }

    @media (max-width: 430px){
        .it &.disclaimer,
        .sk &.disclaimer{
            .title-box .title.sect-title{
                font-size: 28px;
            }
        }
    }
`