import React, {useEffect, useState} from 'react';
import { styled } from 'styled-components';

const ScrollTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 400) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

      
    return (
        <ScrollTop className={'scroll-top-bt' + (isVisible ? ' active' : '')} onClick={scrollToTop}>
            <img src="/img/arrow-scroll-top.svg" alt="Scroll top"/>
        </ScrollTop>
    );
}
  
export default ScrollTopButton;

const ScrollTop = styled.div`
    &.scroll-top-bt{
        position: fixed;
        bottom: 24px;
        right: 130px;
        border-radius: 8px;
        display: inline-block;
        text-align: center;
        min-width: 42px;
        min-height: 48px;
        z-index: 5555;
        box-shadow: 0px -6px 0px 0px #00000040 inset;
        background-color: #B5A894;
        cursor: pointer;
        padding: 12px 12px 17px;
        opacity: 0;
        visibility: hidden;
        transition: .2s ease opacity, .2s ease visibility;

        &.active{
            opacity: 1;
            visibility: visible;
        }
    }

    @media (max-width: 1160px){
        &.scroll-top-bt{
            right: 135px;
        }
    } 

    @media (max-width: 768px){
        &.scroll-top-bt{
            bottom: 120px;
            right: 28px;
        }
    } 
`