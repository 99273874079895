import React from 'react';
import { createGlobalStyle, keyframes } from 'styled-components';

import coopBlackFont from '../assets/fonts/CoopBlack/coopBlackFont.css';
import ttFors from '../assets/fonts/TT_Fors/TT_Fors.css';

import Header from './Header';
import Footer from './Footer';

import HeadSection from './sections/HeadSect';
import AboutSection from './sections/AboutSect';
import MemCoinsSection from './sections/MemcoinsSect';
import ListingSection from './sections/ListingSect';
import HowToBuySection from './sections/HowToBuySect';

import BuySection from './sections/BuySect';

import WhyBuySection from './sections/WhyBuySect';
import MenynomicsSection from './sections/MenynomicsSect';
import RoadmapSection from './sections/RoadmapSect';
import FaqSection from './sections/FaqSect';
import JoinSection from './sections/JoinSect';
import DisclaimerSection from './sections/DisclaimerSect';
import ScrollTopButton from './ui/ScrollTopButton';

function PageTemplate({className}) {
    document.body.classList = className;
    return (
        <>
            <NormalizeStyle />
            <GlobalStyle />

            <Header />
            <main>
                <HeadSection />
                <AboutSection />
                <MemCoinsSection />
                <ListingSection />
                <HowToBuySection />
                <BuySection />
                <WhyBuySection />
                <MenynomicsSection />
                <RoadmapSection />
                <FaqSection />
                <JoinSection />
                <DisclaimerSection />
            </main>
            <Footer />
            <ScrollTopButton />
        </>
    );
}

export default PageTemplate;


const slideUp = keyframes`
  from {
    transform: translate(0, 70px);
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
  }
`;

const slideFromRight = keyframes`
  from {
    transform: translate(50px, 0);
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
  }
`;

const slideFromLeft = keyframes`
  from {
    transform: translate(-50px, 0);
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
  }
`;

const hornLeft = keyframes`
  from {
    transform: translate(-100%, -50%) rotate(70deg);
    transform-origin: center;
    opacity: 1;
    visibility: visible;
  }

  to {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
    visibility: visible;
  }
`;

const hornRight = keyframes`
  from {
    transform: translate(100%, -50%) rotate(-70deg);
    transform-origin: center;
    opacity: 1;
    visibility: visible;
  }

  to {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
    visibility: visible;
  }
`;

const GlobalStyle = createGlobalStyle`

    *{
        --yellow-color: rgba(255, 205, 0, 1);
        --yellow-color-dark: rgba(176, 158, 0, 1);
        --orange-light-color: rgba(251, 209, 137, 1);
        --black-color: rgba(1, 1, 1, 1);
        --light-blue-color: rgba(167, 212, 235, 1);
        --red-color: rgba(228, 73, 73, 1);

    }    
    
    body{
        font-size: 16px;
        line-height:  142%;
        margin:0;
        font-style: normal;
        font-family: 'TT_Fors';
        font-weight: 500;
        -webkit-tap-highlight-color:  transparent;  
        position: relative;
        -webkit-font-smoothing: antialiased;
        color: var(--black-color);
        background-color: var(--light-blue-color);
        overflow-x: hidden;
        
        &.active{
            overflow: hidden;
        }
    } 

    body.cz,
    body.hu,
    body.pl,
    body.ro,
    body.sk,
    body.vi,
    body.tr,
    body.el{
        font-family: "Noto Sans", sans-serif;
    }

    .cz .title-box .title,
    .hu .title-box .title,
    .pl .title-box .title,
    .ro .title-box .title,
    .sk .title-box .title,
    .vi .title-box .title,
    .tr .title-box .title,
    .el .title-box .title{
        font-family: "Noto Sans", sans-serif;
        font-weight: 900;
    }

    .cz .title-box .title.main-title,
    .pl .title-box .title.main-title,
    .sk .title-box .title.main-title,
    .vi .title-box .title.main-title{
        font-size: 84px;
    }

    .el .title-box .title.main-title,
    .ro .title-box .title.main-title,
    .ar .title-box .title.main-title{
        font-size: 70px;
    }

    body.ar{
        font-family: "Noto Kufi Arabic", sans-serif;
    }

    .ar .title-box .title{
        font-family: "Noto Kufi Arabic", sans-serif;
        font-weight: 900;
    }

    body.zh{
        font-family: "Noto Sans SC", sans-serif;
    }

    .zh .title-box .title{
        font-family: "Noto Sans SC", sans-serif;
        font-weight: 900;
    }

    body.zhHant{
        font-family: "Noto Sans TC", sans-serif;
    }

    .zhHant .title-box .title{
        font-family: "Noto Sans TC", sans-serif;
        font-weight: 900;
    }

    body.ja{
        font-family: "Noto Sans JP", sans-serif;
    }

    .ja .title-box .title{
        font-family: "Noto Sans JP", sans-serif;
        font-weight: 900;
    }

    body.ko{
        font-family: "Noto Sans KR", sans-serif;
    }

    .ko .title-box .title{
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 900;
    }

    body.th{
        font-family: "Noto Sans Thai", sans-serif;
    }

    .th .title-box .title{
        font-family: "Noto Sans Thai", sans-serif;
        font-weight: 900;
    }

    a{
        color: blue;

        &:hover{
            text-decoration: none;
            color: blue;
        }
    }

    a:focus-visible{
        outline: none;
    }

    body p{
        margin-bottom: 24px;
    }

    body p:last-child{
        margin-bottom:0;
    } 

    b{
        font-weight: 800;
    }

    img{
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }

    .container{
        width: 100%;
        max-width: 1320px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .rows{
        display: grid;
        grid-gap: 60px;
        grid-auto-rows: min-content;
    }  

    .g-box{
        display: grid;
        grid-gap: 32px;
        grid-auto-rows: min-content;
    }    

    .f-box{
        display: flex;
        flex-wrap: wrap;
    }   

    .sect{
        padding: 60px 0;
    }

    .title-box{
        display: grid;
        grid-gap: 16px;
        font-size: 24px;
        
        .title{
            font-family: 'CoopBlack';
            color: var(--yellow-color);
            font-weight: 500;
            font-size: 32px;
            text-shadow: -2px 2px 0px #010101;
            line-height: 125%;
            margin: 0;

            span{
                color: #fff;
            }

            &.sect-title{
                font-size: 48px;
                line-height: 116.667%;
                text-shadow: -2px 4px 0px #010101;
                max-width: 900px;
                margin: 0 auto;
            }
            
            &.main-title{
                font-size: 80px;
                line-height: 112.5%;
                text-shadow: -2px 4px 0px #010101;
            }
        }
    }

    .align-center{
        text-align: center;
    }

    main{
        overflow-x: hidden;
    }

    .text-box{        
        font-size: 24px;
        font-weight: 700;
        line-height: 133%;
    }

    .link{
        color: rgba(0, 122, 184, 1);
        text-decoration: none;
        border-bottom: 2px solid;

        &:hover{
            color: rgba(0, 122, 184, 1);
            border-color: transparent;
        }
    }

    @media (min-width: 1441px){
        .container{
            position: relative;
        }

        .with-shadow>.container::before,
        .with-shadow>.container::after{
            content: '';
            width: 1000px;
            height: 100%;
            position: absolute;
            top: 0;
        }

        .with-shadow>.container::before{
            left: 0;
            transform: translateX(-360px);
            background: linear-gradient(90deg, #A7D4EB 31.03%, rgba(167, 212, 235, 0.00) 42.09%);
        }
        .with-shadow>.container::after{
            right: 0;
            z-index: 1;
            transform: translateX(360px);
            background: linear-gradient(270deg, #A7D4EB 31.03%, rgba(167, 212, 235, 0.00) 42.09%);
        }

        .head-sect.with-shadow>.container::before,
        .head-sect.with-shadow>.container::after{
            height: calc(100% + 150px + 132px);
            top: -150px;
        }

        .head-sect.with-shadow>.container::after{
            z-index: 3;
        }
    }
    @media (max-width: 1100px){
        .title-box{
            font-size: 20px;
            
            .title{
                font-size: 28px;

                &.sect-title{
                    font-size: 40px;
                    max-width: 770px;
                }
                
                &.main-title{
                    font-size: 65px;
                }
            }
        }
    }

    @media (max-width: 992px){
        .text-box{
            font-size: 18px;
        }

        .container{
            padding: 0 10px;
        }
    }

    @media (max-width: 768px){
        body{
            font-size: 15px;
        }       

        body p{
            margin-bottom: 16px;
        }

        body p:last-child{
            margin-bottom:0;
        } 

        div:not(header)>.container{
            max-width: 395px;
            margin: 0 auto;
        }        

        .text-box{
            font-size: 15px;
        }

        .sect{
            padding: 50px 0;
        }

        .title-box{
            font-size: 15px;
            
            .title{
                font-size: 24px;

                &.sect-title{
                    font-size: 32px;
                    text-shadow: -2px 2px 0px #010101;
                    text-align: center;
                }
                
                &.main-title{
                    font-size: 48px;
                    text-shadow: -2px 2px 0px #010101;
                }
            }
        }
    }

    @media (max-width: 430px){
        div:not(header)>.container{
            max-width: 100%;
        }
    }

    @media (max-width: 365px){
        body{
            font-size: 15px;
        }

        .title-box{            
            .title{
                font-size: 20px;

                &.sect-title{
                    font-size: 28px;
                }
                
                &.main-title{
                    font-size: 40px;
                }
            }
        }
    }

    .slideUp,
    .slideFromLeft,
    .slideFromRight,
    .hornLeft,
    .hornRight{ 
        opacity: 0;
        visibility: hidden;
    }

    .slideUp.active{
        animation: ${slideUp} 1s ease-out forwards;
    }

    .slideFromLeft.active{
        animation: ${slideFromLeft} 1s ease-out forwards;
    }

    .slideFromRight.active{
        animation: ${slideFromRight} 1s ease-out forwards;
    }

    .hornLeft.active{
        animation: ${hornLeft} 1s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
    }

    .hornRight.active{
        animation: ${hornRight} 1s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
    }
`
const NormalizeStyle = createGlobalStyle`
    *,
    *::before,
    *::after {
    box-sizing: border-box;
    }

    body {
    margin: 0;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    hr {
        margin: 1rem 0;
        color: inherit;
        border: 0;
        border-top: #000 solid;
        opacity: 0.25;
    }

    h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: var(--bs-heading-color, inherit);
    }

    h1, .h1 {
        font-size: calc(1.375rem + 1.5vw);
    }
    @media (min-width: 1200px) {
        h1, .h1 {
            font-size: 2.5rem;
        }
    }

    h2, .h2 {
        font-size: calc(1.325rem + 0.9vw);
    }
    @media (min-width: 1200px) {
        h2, .h2 {
            font-size: 2rem;
        }
    }

    h3, .h3 {
        font-size: calc(1.3rem + 0.6vw);
    }
    @media (min-width: 1200px) {
        h3, .h3 {
            font-size: 1.75rem;
        }
    }

    h4, .h4 {
        font-size: calc(1.275rem + 0.3vw);
    }
    @media (min-width: 1200px) {
        h4, .h4 {
            font-size: 1.5rem;
        }
    }

    h5, .h5 {
        font-size: 1.25rem;
    }

    h6, .h6 {
        font-size: 1rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    abbr[title] {
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
        cursor: help;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
    }

    address {
        margin-bottom: 1rem;
        font-style: normal;
        line-height: inherit;
    }

    ol,
    ul {
        padding-left: 2rem;
    }

    ol,
    ul,
    dl {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
        margin-bottom: 0;
    }

    dt {
        font-weight: 700;
    }

    dd {
        margin-bottom: 0.5rem;
        margin-left: 0;
    }

    blockquote {
        margin: 0 0 1rem;
    }

    b,
    strong {
        font-weight: bolder;
    }

    small, .small {
        font-size: 0.875em;
    }

    mark, .mark {
        padding: 0.1875em;
        background-color: var(--bs-highlight-bg);
    }

    sub,
    sup {
        position: relative;
        font-size: 0.75em;
        line-height: 0;
        vertical-align: baseline;
    }

    sub {
        bottom: -0.25em;
    }

    sup {
        top: -0.5em;
    }

    a {
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
        text-decoration: underline;
    }
    a:hover {
        --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
    }

    a:not([href]):not([class]), a:not([href]):not([class]):hover {
        color: inherit;
        text-decoration: none;
    }

    pre,
    code,
    kbd,
    samp {
        font-family: var(--bs-font-monospace);
        font-size: 1em;
    }

    pre {
        display: block;
        margin-top: 0;
        margin-bottom: 1rem;
        overflow: auto;
        font-size: 0.875em;
    }
    pre code {
        font-size: inherit;
        color: inherit;
        word-break: normal;
    }

    code {
        font-size: 0.875em;
        color: var(--bs-code-color);
        word-wrap: break-word;
    }
    a > code {
        color: inherit;
    }

    kbd {
        padding: 0.1875rem 0.375rem;
        font-size: 0.875em;
        color: var(--bs-body-bg);
        background-color: var(--bs-body-color);
        border-radius: 0.25rem;
    }
    kbd kbd {
        padding: 0;
        font-size: 1em;
    }

    figure {
        margin: 0 0 1rem;
    }

    img,
    svg {
        vertical-align: middle;
    }

    table {
        caption-side: bottom;
        border-collapse: collapse;
    }

    caption {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: var(--bs-secondary-color);
        text-align: left;
    }

    th {
        text-align: inherit;
        text-align: -webkit-match-parent;
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }

    label {
        display: inline-block;
    }

    button {
        border-radius: 0;
    }

    button:focus:not(:focus-visible) {
        outline: 0;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    button,
    select {
        text-transform: none;
    }

    [role=button] {
        cursor: pointer;
    }

    select {
        word-wrap: normal;
    }
    select:disabled {
        opacity: 1;
    }

    [list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
        display: none !important;
    }

    button,
    [type=button],
    [type=reset],
    [type=submit] {
        -webkit-appearance: button;
    }
    button:not(:disabled),
    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled) {
        cursor: pointer;
    }

    ::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }

    textarea {
        resize: vertical;
    }

    fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }

    legend {
        float: left;
        width: 100%;
        padding: 0;
        margin-bottom: 0.5rem;
        font-size: calc(1.275rem + 0.3vw);
        line-height: inherit;
    }
    @media (min-width: 1200px) {
        legend {
            font-size: 1.5rem;
        }
    }
    legend + * {
        clear: left;
    }

    ::-webkit-datetime-edit-fields-wrapper,
    ::-webkit-datetime-edit-text,
    ::-webkit-datetime-edit-minute,
    ::-webkit-datetime-edit-hour-field,
    ::-webkit-datetime-edit-day-field,
    ::-webkit-datetime-edit-month-field,
    ::-webkit-datetime-edit-year-field {
        padding: 0;
    }

    ::-webkit-inner-spin-button {
        height: auto;
    }

    [type=search] {
        outline-offset: -2px;
        -webkit-appearance: textfield;
    }

    /* rtl:raw:
    [type="tel"],
    [type="url"],
    [type="email"],
    [type="number"] {
        direction: ltr;
    }
    */
    ::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    ::-webkit-color-swatch-wrapper {
        padding: 0;
    }

    ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button;
    }

    ::file-selector-button {
        font: inherit;
        -webkit-appearance: button;
    }

    output {
        display: inline-block;
    }

    iframe {
        border: 0;
    }

    summary {
        display: list-item;
        cursor: pointer;
    }

    progress {
        vertical-align: baseline;
    }

    [hidden] {
        display: none !important;
    }


    .blockquote {
        margin-bottom: 1rem;
        font-size: 1.25rem;
    }
    .blockquote > :last-child {
        margin-bottom: 0;
    }

    .blockquote-footer {
        margin-top: -1rem;
        margin-bottom: 1rem;
        font-size: 0.875em;
        color: #6c757d;
    }
    .blockquote-footer::before {
        content: "— ";
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
    }

    .img-thumbnail {
        padding: 0.25rem;
        background-color: var(--bs-body-bg);
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        max-width: 100%;
        height: auto;
    }

    .figure {
        display: inline-block;
    }

    .figure-img {
        margin-bottom: 0.5rem;
        line-height: 1;
    }

    .figure-caption {
        font-size: 0.875em;
        color: var(--bs-secondary-color);
    }

`