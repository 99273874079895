const scrollFunction = (id, options = {}) => {
    const { windowWidth, menuOpen, setMenuOpen } = options;
    
    window.scrollTo({
        behavior: 'smooth',
        top: document.getElementById(id).getBoundingClientRect().top - document.body.getBoundingClientRect().top,
    });
  
    if (windowWidth < 1200 && menuOpen) {
        setMenuOpen(false);
    }
  };
  
  export default scrollFunction;
  