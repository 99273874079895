import React, { useContext } from 'react';
import { styled, keyframes } from 'styled-components';
import parse from 'html-react-parser';

import useAnimateOnScroll from '../hooks/useAnimateOnScroll';
import Timer from '../parts/Timer';
import FormExchange from '../form/FormExchange';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const BuySectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');
    const { language } = useContext(LanguageContext);
    const text = translations.buySect[language];

    return (
        <BuySection className="sect buy-sect g-box with-shadow" id="buy-sect">
            <div className="container">
                <div className="form-block g-box">
                    <div className="g-box">
                        <div className="title-box">
                            <h3 className="title sect-title align-center">{text ? parse(text.title) : ''}</h3>
                            <h4 className="title align-center">{text ? parse(text.subtitle) : ''}</h4>
                        </div>
                        <Timer />
                        <div className="text-box align-center">
                            <p>{text ? parse(text.text1) : ''}</p>
                        </div>
                    </div>
                    <div className="variants g-box">
                        <FormExchange />
                        <div className="align-center">
                            <p><b>{text ? parse(text.text2) : ''}</b></p>
                        </div>
                    </div>
                    <div className="coin left">
                        <img src="/img/coins/coin-1-shadow.svg" alt="Coin" />
                    </div>
                    <div className="coin right">
                        <img src="/img/coins/coin-1-shadow.svg" alt="Coin" />
                    </div>
                </div>
            </div>
            <div className="horn-box left hornLeft" ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
            <div className="horn-box right hornRight" ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
        </BuySection>
    );
};

export default BuySectionComponent;



const coinAnim = keyframes`
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    20% {
        transform: translate(1px, -3px) rotate(2deg);
    }
    40% {
        transform: translate(-1px, -1px) rotate(-2deg);
    }
    60% {
        transform: translate(3px, 1px) rotate(3deg);
    }
    80% {
        transform: translate(-3px, 3px) rotate(-3deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
`;

const BuySection = styled.div`
    &.buy-sect{
        background-image: url(/img/sections/buy-sect/buy-section-bg.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        position: relative;
        padding: 105px 0 50px;
        margin-bottom: 120px;

        &::before {
            content: '';
            width: 100%;
            height: 208px;
            background-image: url(/img/clouds/clouds3.svg);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: center;
            position: absolute;
            top: -100px;
            left: 0;
            z-index: 3;
        }

        &::after {
            content: '';
            width: 100%;
            height: 194px;
            background-image: url(/img/clouds/clouds.svg);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: center;
            position: absolute;
            bottom: -154px;
            left: 0;
            z-index: 3;
        }

        .coin{
            position: absolute;
            animation: ${coinAnim} 5s ease-in-out infinite alternate;
            transform: translate3d(0, 0, 0);

            &.left{
                max-width: 235px;
                top: 570px;
                left: -206px;
                animation-delay: 2s;

                img{
                    transform: scale(-1, 1);
                }
            }

            &.right{
                max-width: 315px;
                top: 0;
                right: -245px;

            }
        }

        .horn-box {
            width: 398px;
            position: absolute;
            z-index: 3;

            &.left{
                top:238px;
                left:0;

                img{
                    transform: scale(-1, 1);
                }
            }

            &.right{
                top:362px;
                right:0;
            }
        }
    
        .form-block{
            border-radius: 16px;
            background: #A7D4EB;
            box-shadow: -4px 4px 0px 0px #010101;
            grid-gap: 24px;
            max-width:726px;
            margin: 0 auto;
            padding: 24px;
            position: relative;

            &>div{
                grid-gap: 16px;
            }

            .title-box{
                grid-gap: 8px;
            }

            .timer{
                width:100%;
                display: block;
                max-width: calc(100% - 80px);
                margin: 0 auto;
            }

            .text-box{
                margin: 0 auto;
                max-width: 590px;
            }

            label{
                font-style: normal;
                font-weight: 800;
                margin-bottom: 8px;
                width: 100%;
                display: flex;    
                align-items: center;

                .tooltip-box{
                    margin-left: 10px;
                }
            }
        }

        .wallet-info{
            background-color: rgba(220, 238, 247, 1);
            padding: 16px;
            border-radius: 10px;
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;

            p:not(:last-child){
                margin-bottom: 8px;
            }

            .btn{
                padding: 8px;
            }

            .buttons-box{
                display: flex;
                gap: 8px;
            }
        }

        .copy-group label{
            text-align: center;
            display: block;
        }

        .variants{
            grid-gap: 16px;
        }

        .form-box{
            grid-gap: 32px;

            .btn,
            .bt-box{
                width: 100%;
            }

            .copy-group label{
                text-align: center;
            }

            .fields-list{
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 16px;
            }

            .form-group{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            input{
                width: 100%;
                padding: 10px 16px;
                min-height: 48px;
            }
            
            .select__control{
                box-shadow: none;
                background-color: var(--yellow-color);
                border-color: var(--yellow-color);
                padding: 10px 16px;

                &:hover{
                    background-color: rgba(255, 245, 153, 1);
                    border-color: rgba(255, 245, 153, 1);
                }

                input{
                    padding: 0;
                    min-height: initial;
                }

                .select__value-container{
                    padding: 0;
                }

                .select__indicator svg path{
                    stroke: var(--black-color);
                }
            }

            .refferal{
                width:100%;
                max-width: 391px;
                margin: 0 auto;
                grid-column-start: span 2;

                label{
                    grid-column-start: span 2;
                }
            }
        }
        
        .form-group label .tooltip{
            width: 305px;
        }
    }

    @media (min-width: 1441px){
       &.buy-sect{
            padding: 0;
            background-size: auto 110%;
        
            &>.container{
                padding: 105px 20px 50px;
            }

            .form-block{
               z-index: 3;
            }
       } 
    }

    @media (max-width: 1439px){
        &.buy-sect{
        
            .horn-box {
                width: 23vw;

                &.right{
                    top: 410px;
                }
            }
        }
    }

    @media (max-width: 1200px){
        &.buy-sect{
            .coin{
                &.right{
                    max-width: 260px;
                    top: 26px;
                    right: -198px;
                }

                &.left{
                    max-width: 187px;
                    top: 555px;
                    left: -164px;
                }
            }

            .horn-box {
                &.right{
                    top: 362px;
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1024px){
        textarea{
            height: 56px;
            padding: 15px 16px;
            padding-right: 62px;     
        }
    
    }

    @media (max-width: 1100px){
        &.buy-sect{
        
            .horn-box {
                width: 16vw;
            }
        }
    }

    @media (max-width: 992px){
        &.buy-sect{
            &::before {
                height: 180px;
                top: -70px;
            }

            .coin{
                &.right{
                    max-width: 200px;
                    top: 60px;
                    right: -145px;
                }

                &.left{
                    max-width: 150px;
                    top: 579px;
                    left: -125px;
                }
            }
        
            .horn-box {
                width: 14vw;
            }
        
            .form-group label .tooltip{
                width: 200px;
            }

            .wallet-info .tooltip{
                display: none;
            }
        }
    }

    @media (max-width: 768px){
        &.buy-sect{   
            margin-bottom: 50px;
            background-size: auto 100%;
            padding: 70px 0 50px;

            &::before {
                height: 82px;
                top: -45px;
            }

            &::after {
                height: 82px;
                bottom: -58px;
            }
        
            .horn-box {
                width: 82px;

                &.left{
                    top: 42px;
                }

                &.right{
                    display: none;
                }
            }

            .form-block{
                padding: 24px 8px;

                .title-box{
                    max-width: 275px;
                    margin: 0 auto;
                }

                .timer{
                    max-width: 100%;
                }

                .variants{
                    grid-gap: 8px;
                }

                .text-box .link{
                    font-size: 24px;
                    line-height: 133.333%; 
                }
            }

            .coin{
                &.right{
                    max-width: 141px;
                    top: 32px;
                    right: -72px;
                }

                &.left {
                    max-width: 85px;
                    top: initial;
                    left: -45px;
                    bottom: -38px;
                }
            }

            .form-box{
            
                .fields-list{
                    grid-template-columns: 1fr;
                }

                .refferal{
                    grid-template-columns: 1fr;
                    grid-column-start: initial;

                    label{
                        grid-column-start: initial;
                    }

                    .btn{
                        margin-top: 8px;
                    }
                }
            }
        }

        .nl &.buy-sect,
        .fr &.buy-sect,
        .de &.buy-sect{
            .form-block .title-box .title.sect-title{
                font-size: 28px;
            }
        }
    }

    @media (max-width: 430px){
        &.buy-sect{
            &::before {
                height: 60px;
                top: -30px;
            }

            &::after {
                height: 60px;
                bottom: -40px;
            }

            .wallet-info{
                grid-template-columns: 1fr;
                display: grid;
                grid-gap: 16px;

                .buttons-box{
                    justify-content: flex-end;
                }
            }
        
            .form-group label .tooltip{
                width: auto;
            }
        }
    }
`