import React, { useContext } from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const HowToBuySectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');
    const { language } = useContext(LanguageContext);
    const text = translations.howToBuySect[language];

    return (
        <HowToBuySection className="sect how-to-buy g-box" id="how-to-buy">
            <div className="container">
                <div className="columns g-box">
                    <div className="info-box g-box">
                        <div className="title-box slideUp" ref={setRef}>
                            <h3 className="title sect-title">{text ? parse(text.title) : ''}</h3>
                        </div>
                        <div className="text-box slideUp" ref={setRef}>
                            {text ? parse(text.text) : ''}
                        </div>
                    </div>
                    <div className="list-box g-box">
                        {text.items.map((item, index) => (
                            <div key={index} className="item slideUp" ref={setRef}>
                                <div className="cont-box g-box">
                                    <div className="title-box">
                                        <p className="title">{text ? parse(item.title) : ''}</p>
                                    </div>
                                    <div className="text">
                                        {text ? parse(item.text) : ''}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="img-box">
                        <img src="/img/chest-with-coins.svg" alt="" />
                    </div>
                </div>
            </div>
            <div className="horn-box hornRight"  ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
        </HowToBuySection>
    )
};
  
export default HowToBuySectionComponent;

const HowToBuySection = styled.div`
    &.how-to-buy{
        background-color: var(--orange-light-color);
        position: relative;

        .horn-box{
            width: 172px;
            position: absolute;
            top: -70px;
            right: 0;
        }
        
        .columns{
            grid-template-columns: 416px 1fr;
            grid-gap: 22px 16px;
        }

        .info-box{
            grid-gap: 24px;

            .text-box{
                font-weight: 500;
            }
        }

        .list-box{
            grid-row-start: span 2;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px 16px;

            .item{
                counter-increment: section;
                padding: 40px;
                padding-bottom: 65px;
                color: #fff;
                position: relative;
                font-size: 16px;

                a{
                    color: var(--yellow-color);
                    text-decoration: none;
                }

                .text{
                    p{
                        margin-bottom: 16px;
                        max-width: 287px;
                        line-height: 150%;

                        &:last-child{
                            margin-bottom: 0;
                        }
                    }

                    .note{
                        color: var(--black-color);
                        max-width: none;
                        font-size: 14px;
                        line-height: 142%;
                    }
                }
                
                &:nth-child(4) .text p{
                    max-width: initial;
                }


                .cont-box{
                    grid-gap: 16px;
                    position: relative;
                }

                .title::before {
                    content: counter(section)". ";
                }

                &::before{
                    content: '';
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: top;
                }

                &:nth-child(1)::before{
                    background-image: url(/img/sections/how-to-buy/item-bg-1.png);
                }

                &:nth-child(2)::before{
                    background-image: url(/img/sections/how-to-buy/item-bg-2.png);
                }

                &:nth-child(3)::before{
                    background-image: url(/img/sections/how-to-buy/item-bg-3.png);
                }

                &:nth-child(4)::before{
                    background-image: url(/img/sections/how-to-buy/item-bg-4.png);
                }
            }
        }
    }

    @media (min-width: 1200px){
        .ru &.how-to-buy,
        .de &.how-to-buy,
        .it &.how-to-buy,
        .pl &.how-to-buy{
            .info-box .title-box{
                .title{
                    font-size: 42px;
                }
            }
        }

        .fr &.how-to-buy,
        .nl &.how-to-buy,
        .de &.how-to-buy,
        .it &.how-to-buy,
        .pt &.how-to-buy{
            .list-box .item .title-box{
                .title{
                    font-size: 26px;
                }
            }
        }

        .hu &.how-to-buy,
        .pl &.how-to-buy{
            .list-box .item .title-box{
                .title{
                    font-size: 28px;
                }
            }
        }
    } 
        
    @media (min-width: 992px) and (max-width: 1200px){
        .ru &.how-to-buy,
        .de &.how-to-buy,
        .it &.how-to-buy,
        .pl &.how-to-buy{
            .info-box .title-box{
                .title{
                    font-size: 28px;
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1200px){
        .fr &.how-to-buy,
        .nl &.how-to-buy{
            .list-box .item .title-box{
                .title{
                    font-size: 22px;
                }
            }
        }

        .de &.how-to-buy,
        .it &.how-to-buy,
        .pl &.how-to-buy,
        .pt &.how-to-buy,
        .es &.how-to-buy{
            .list-box .item .title-box{
                .title{
                    font-size: 25px;
                }
            }
        }

        .nl &.how-to-buy{
            .info-box .title-box{
                .title{
                    font-size: 34px;
                }
            }
        }
    }

    

    @media (max-width: 1200px){
        &.how-to-buy{
            .columns{
                grid-template-columns: 28.9vw 1fr;
            }
        }
    }

    @media (max-width: 992px){
        &.how-to-buy{

            .title-box .title.sect-title{
                text-align: center;
            }

            .columns{
                grid-template-columns: 1fr;
                grid-gap: 32px;

                .img-box{
                    text-align: center;
                }
            }

            .list-box{
                grid-column-start: initial;
                max-width: 795px;
                margin: 0 auto;
            }

            .info-box{
                .text-box{
                    max-width: 530px;
                    margin: 0 auto;
                }
            }
        }
    
    }

    @media (max-width: 768px){
        &.how-to-buy{
            .horn-box{
                width: 135px;
                top: -57px;
            }
            .columns{
                .list-box{
                    grid-template-columns: 1fr;
                }
            }

            .list-box{
                .item,
                .item:nth-child(4),
                .item:nth-child(3){
                    padding: 48px 30px 70px;
                    min-height: 285px;
                }
            }
        }
    }

`