import React, { useState } from 'react';
import { styled } from 'styled-components';
import Btn from './Btn';
import Loader from './Loader/Loader';

const Popup = ({onClickBt, handlePopup}) => {
    
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [data, setData] = useState({})

    const handleClick = async () => {
        setLoading(true)
        const res = await onClickBt()
        
        if(res?.id >= 100 && res?.id < 200) {
            setLoading(false)
            setError(res)
        } else if(res?.id >= 200 && res?.id < 300) {
            if(res?.id === 200) {
                setData(res)
                setStep(2)
                const newTab = window.open('https://menycoin.com/success.html', '_blank');
                setTimeout(() => {
                    if (newTab && !newTab.closed) {
                        newTab.close();
                    }}, 500);
            } else if(res?.id === 201) {
                setData(res) // тут третьего шага нету в функции но на всякий пусть будет
                setStep(3)
            }
            setLoading(false)
        } 
    }

    return(
        <PopupWraper className="popup-wrap">
            <div className="popup-box">
                {step === 1 && 
                    <div className="close-bt" onClick={handlePopup}></div>
                }
                {step === 1 &&
                    <div className="content">
                        <div className="title-box align-center">
                            <p className="title">Please read carefully</p>
                        </div>
                        <div className="text-box">
                            <p>By clicking on the Buy button, you confirm that you are participating in the presale. Tokens will be send to your wallet automatically after purchase. You accept all risks and conditions stated on <a href="/" rel="nofollow">menycoin.com</a>.</p>
                        </div>
                        <div className="align-center">
                            <Btn type="button" className="big" onClick={handleClick}>
                                {loading ? 
                                    <div className="loader-box">
                                        <Loader width={'20px'} height={'20px'} color={'#fff'}/>
                                    </div>
                                :
                                    'Buy $MENY tokens now'
                                }
                            </Btn>
                        </div>
                        {error?.id && 
                            <div className="error-message">
                                <p>{error.text}</p>
                            </div>
                        }
                    </div>
                } 
                {step === 2 &&
                    <div className="content">
                        <div className="title-box align-center">
                            <p className="title">Transaction success</p>
                        </div>
                        <div className="text-box">
                            <p>
                                Your transaction has been accepted and is awaiting confirmation with blocks.<br />Transaction Hash: <span className="hash">{data?.hash}</span><br />See the status of the transaction you can follow this link: <a target='_blank' href={`${data?.link}`}>Link</a>
                            </p>
                        </div>
                        <div className="align-center">
                            <Btn type="button" className="big" onClick={handlePopup}>
                                Close
                            </Btn>
                        </div>
                    </div>
                }
            </div>
        </PopupWraper>
    )
};
  
export default Popup;
  
const PopupWraper = styled.div`
    &.popup-wrap{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: scroll;
        padding: 50px;

        &::before{
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width:100%;
            height: 100%;
            background: rgba(0, 170, 255, 0.5);    
            z-index: -1;
        }

        .close-bt{
            width: 30px;
            height: 30px;
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;

            &::before,
            &::after{
                content: '';
                width: 24px;
                height: 2px;
                border-radius: 4px;
                background-color: var(--yellow-color);
                position: absolute;
                top: 50%;
                left: 50%;
            }

            &::before{
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &::after{
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        .popup-box{
            display: block;
            width: 100%;
            max-width: 632px;
            padding: 24px;
            margin: auto;
            background-color: rgba(0, 98, 148, 1);
            border-radius: 16px;
            position: relative;

            .title-box{
                max-width: 90%;
                margin: 0 auto;
            }
        }

        .content{
            display: grid;
            grid-gap: 24px;
            font-size: 24px;
            color: rgba(255, 255, 255, 1);
        }

        .bt-box{
            .btn{
                min-width: 360px;
                height: 63px;
            }
        }

        .loader-box{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .error-message{
            display: flex;
            justify-content: center;
            color: #EA4D4D;
            font-size: 16px;
        }

        .hash{
            word-break: break-all;
            color: lightgrey;
        }
    
    }

    @media (max-width: 768px){
        &.popup-wrap{
            padding: 30px 13px;

            .popup-box{
                max-width:410px;
                padding: 16px;
            }
        
        }
    }

    @media (max-width: 430px){
        &.popup-wrap{
            .bt-box{
                width:100%;

                .btn{
                    padding: 12px 10px 17px;
                    width:100%;
                    max-width:100%;
                    font-size:22px;
                    min-width: 100%;
                    height: auto;
                }
            }
        
        }
    }
`