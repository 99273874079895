import React from 'react';
import { styled } from 'styled-components';

const Btn = ({ type, children, className, ...props}) => {
  switch (type) {
    case 'button':
      return (
        <BtnComponent className="bt-box">
            <button {...props} className={'btn' + (className ? ' ' + className : '')}>{children}</button>
        </BtnComponent>
      )
    case 'submit':
      return (
        <BtnComponent className="bt-box">
            <input type="submit" value={children} {...props} className={'btn' + (className ? ' ' + className : '')}/>
        </BtnComponent>
      );
    case 'btLikeLink':
      return (
        <BtnComponent className="bt-box">
            <span {...props} className={'btn-like-link' + (className ? ' ' + className : '')}>{children}</span>
        </BtnComponent>
      );
    case 'link':
      return (
        <BtnComponent className="bt-box">
            <a {...props} className={'btn' + (className ? ' ' + className : '')}>{children}</a>
        </BtnComponent>
    );
    default:
      return null;
  }
};

export default Btn;

const BtnComponent = styled.div`
    display: inline-block;

    .btn{
        font-weight: 700;
        padding: 8px 30px 14px 30px;
        border-radius: 8px;
        color: #000;
        display: inline-block;
        text-align: center;
        min-width: 42px;
        min-height:48px;
        --box-shadow-color: var(--yellow-color-dark);
        box-shadow: 0px -6px 0px 0px var(--box-shadow-color) inset;
        border: 1px solid var(--yellow-color-dark);
        background-color: var(--yellow-color);

        &:hover{
            color: var(--black-color);
            --box-shadow-color: transparent;
        }

        &:active{
            box-shadow: 0px 6px 4px 0px #B09E00 inset;
        }

        &:disabled{
          filter: grayscale(1);
        }

        &.white{
            --box-shadow-color: rgba(66, 66, 66, 1);
            background-color: white;
            border: 1px solid var(--black-color);

            &:hover{
                --box-shadow-color: transparent;
            }

            &:active{
                box-shadow: 0px 4px 6px 0px var(--black-color) inset;
            }
        }

        &.big{
          font-size:24px;
          padding: 12px 30px 17px;
          line-height: 133%;
          min-height:63px;
        }

        &>img{
          width: 100%;
          max-width: 26px;
          margin-bottom: 4px;
        }    
    }

    a.btn{
        text-decoration: none;
    }

    .btn-like-link{
      color: rgba(0, 122, 184, 1);
      border-bottom: 2px solid;
      font-weight: 700;
      cursor: pointer;

      &:hover{
        border-color: transparent;
      }
    }

    @media (max-width: 768px){
      .btn{
        &.big{
          padding: 12px 25px 17px;
        }
      }
    }
`