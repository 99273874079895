import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');
    const navigate = useNavigate();

    useEffect(() => {
        const pathLanguage = window.location.pathname.split('/')[1];
        const savedLanguage = localStorage.getItem('language') || 'en';
    
        if (!pathLanguage || pathLanguage === 'en') {
            if (savedLanguage !== 'en') {
                setLanguage('en');
                localStorage.setItem('language', 'en');
            } else {
                setLanguage('en');
            }
            navigate('/', { replace: true });
        } else {
            if (pathLanguage !== savedLanguage) {
                setLanguage(pathLanguage);
                localStorage.setItem('language', pathLanguage);
            } else {
                setLanguage(savedLanguage);
            }
            navigate(`/${pathLanguage}/`, { replace: true });
        }
    }, [navigate]);

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);

        if (newLanguage === 'en') {
            navigate('/', { replace: true });
        } else {
            navigate(`/${newLanguage}`, { replace: true });
        }
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
