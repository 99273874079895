import React, { useContext } from 'react';
import { styled, keyframes } from 'styled-components';
import parse from 'html-react-parser';
import Btn from '../ui/Btn';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';
import scrollFunction from '../../utils/scrollFunction';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const MemCoinsSectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');

    const { language } = useContext(LanguageContext);
    const text = translations.memcoinsSect[language];
    
    return (
        <MemCoinsSection className="sect memcoins g-box with-shadow">
            <div className="container">
                <div className="rows">
                    <div className="title-box slideUp" ref={setRef}>
                        <h3 className="title sect-title align-center">{text ? parse(text.title) : ''}</h3>
                    </div>
                    <div className="columns g-box">
                        <div className="info-box g-box slideUp" ref={setRef}>
                            <div className="text-box">
                                {text ? parse(text.text) : ''}
                            </div>
                            <Btn type="button" className="big" onClick={() => scrollFunction('buy-sect')}>{text?.buttonText || ''}</Btn>
                        </div>
                        <div className="images-box">                            
                            <div className="meny-box" ref={setRef}>
                                <img src="/img/meny/meny-3.svg" alt="Meny" />
                            </div>
                            <div className="mems-box">
                                <img src="/img/sections/memcoins/mems-img.svg" alt="Mems" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="horn-box hornRight" ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
        </MemCoinsSection>
    )
};
  
export default MemCoinsSectionComponent;

const menyAnim = keyframes`
    from {
        transform: translate(50px, 18px) scale(0.95);
        opacity: 0;
    }

    to {
        transform: translate(0, 18px) scale(1);
        opacity: 1;
        visibility: visible;
    }
`;

const MemCoinsSection = styled.div`
    &.memcoins{
        padding-bottom: 0;
        background-image: url(/img/sections/memcoins/memcoins-bg.svg);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: bottom center;
        position: relative;
        
        .columns{
            grid-gap: 65px;
        }

        &::after {
            content: '';
            width: 100%;
            height: 194px;
            background-image: url(/img/clouds/clouds2.svg);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: center;
            position: absolute;
            bottom: -144px;
            left: 0;
            z-index: 3;
        }

        .horn-box{
            width: 496px;
            position: absolute;
            top: 230px;
            right: 0;
            z-index: 3;
        }

        .info-box{
            max-width: 770px;
            grid-gap: 24px;
        }

        .images-box{
            position: relative;

            
            .mems-box{
                position: absolute;
            }

            .meny-box{
                transform: translateY(18px);
                opacity: 0;

                &.active{
                    animation: ${menyAnim} 1s ease-out forwards;
                }

                img{
                    max-width:789px;
                }
            }

            .mems-box{
                bottom: -46px;
                right: 0;

                img{
                    max-width:856px;
                }
            }
        }
    }

    @media (max-width: 1300px){
        &.memcoins{
            .horn-box{
                width: 400px;
            }


            .info-box{
                max-width:60%;
            }
        }
    }

    @media (max-width: 992px){
        &.memcoins{
            &::after{
                bottom: -150px;
            }

            .horn-box {
                width: 290px;
            }

            .images-box{
                text-align: center;

                .mems-box{
                    width: 100%;
                }

                .meny-box{
                    width:100%;
                }
            }
        }
    }

    @media (max-width: 768px){
        &.memcoins{
            background-size: 180%;

            &::after{
                height: 72px;
                bottom: -52px;
            }

            .title-box{
                max-width:300px;
                margin: 0 auto;
            }

            .horn-box{
                width: 135px;
                top: 560px;
            }


            .columns{
                grid-gap: 72px;
            }

            .images-box{

                .meny-box{
                    width: 100%;
                    margin: 0 auto;
                    transform: initial;
                    margin-bottom: 60px;
                }

                
                .mems-box{
                    width: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -16px;
                }

                .meny-box img,
                .mems-box img{
                    width: 100%;
                }
            }

            .info-box {
                max-width: initial;
            }

            .btn{
                width:100%;
            }

            .rows{
                grid-gap: 16px;
            }

        }
    }

    @media (max-width: 430px){
        &.memcoins{
            .horn-box{
                top: 63%;
            }
        }
    }

    @media (max-width: 350px){
        &.memcoins{
            .horn-box{
                top: 67%;
            }
        }
    }
`