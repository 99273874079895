import React, { useContext } from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const WyBuySectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');

    const { language } = useContext(LanguageContext);
    const text = translations.whyBuySect[language];

    return (
        <WyBuySection className="sect why-buy g-box">
            <div className="container">
                <div className="rows">
                    <div className="title-box align-center slideUp" ref={setRef}>
                        <h3 className="title sect-title">{text ? parse(text.title) : ''}</h3>
                        <p><b>{text ? parse(text.text) : ''}</b></p>
                    </div>
                    <div className="list-box g-box">
                        {text.items.map((item, index) => (
                            <div className="item slideUp" key={index} ref={setRef}>
                                <div className="cont-box g-box">
                                    <div className="title-box align-center">
                                        <p className="title">{text ? parse(item.title) : ''}</p>
                                    </div>
                                    <div className="text">
                                        <p>{text ? parse(item.text) : ''}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="dop-text align-center slideUp" ref={setRef}>
                        <p><b>{text ? parse(text.text2) : ''}</b></p>
                    </div>
                </div>
            </div>
        </WyBuySection>
    )
};
  
export default WyBuySectionComponent;

const WyBuySection = styled.div`
    &.why-buy{
    
        .rows{
            grid-gap: 30px;
        }

        .list-box{
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px 16px;

            .item{
                padding: 40px;
                padding-bottom: 90px;
                color: #fff;
                position: relative;
                font-size: 16px;

                a{
                    color: var(--yellow-color);
                    text-decoration: none;
                }

                .text{
                    p{
                        margin-bottom: 16px;
                        line-height: 150%;

                        &:last-child{
                            margin-bottom: 0;
                        }
                    }

                    .note{
                        color: var(--black-color);
                        max-width: none;
                        font-size: 14px;
                        line-height: 142%;
                    }
                }

                &:nth-child(1) .text p{
                    max-width: 287px;
                }

                &:nth-child(2) .text p{
                    max-width: 319px;
                }

                &:nth-child(3) .text p{
                    max-width: 334px;
                }

                .cont-box{
                    grid-gap: 16px;
                    position: relative;
                }

                &::before{
                    content: '';
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: top;
                }

                &:nth-child(1)::before{
                    background-image: url(/img/sections/why-buy/item-bg-1.png);
                }

                &:nth-child(2)::before{
                    background-image: url(/img/sections/why-buy/item-bg-2.png);
                }

                &:nth-child(3)::before{
                    background-image: url(/img/sections/why-buy/item-bg-3.png);
                }
            }
        }

        .dop-text{
            font-size: 24px;
        }
    }

    @media (max-width: 1100px){
        &.why-buy{
            .list-box{
                .item{
                    padding: 40px 30px;
                    padding-bottom: 70px;
                }
            }
        }
    }

    @media (max-width: 992px){
        &.why-buy{
            .list-box{
                grid-template-columns: repeat(2, 1fr);
                max-width: 778px;
                margin: 0 auto;

                .item{
                    padding: 40px;
                    padding-bottom: 90px;
                }
            }

            .dop-text {
                font-size: 24px;
            }
        }
    }

    @media (max-width: 768px){
        &.why-buy{
            padding-bottom: 60px;

            .rows{
                grid-gap: 24px;
            }
            .list-box{
                grid-template-columns: 1fr;
                grid-gap: 16px;

                .item{
                    padding: 42px 30px 70px;
                }
            }

            .dop-text {
                font-size: 15px;
            }
        }
    }
`