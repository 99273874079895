import React from 'react';
import { styled } from 'styled-components';

const SocialList = ({...props}) => {
    return (
        <List className="social-list f-box">
            {props.x && (
                <div>
                    <a href="https://x.com/Meny_coin" className="twitter" target="_blank" rel="noreferrer">
                        <img src="/img/social/x-ico.svg" alt="Meny-twitter" />
                    </a>
                </div>
            )}
            {props.telegram && (
                <div>
                    <a href="https://t.me/menycoin" className="telegram" target="_blank" rel="noreferrer">
                        <img src="/img/social/telegram-ico.svg" alt="Meny-telegram" />
                    </a>
                </div>
            )}
            {props.instagram && (
                <div>
                    <a href="https://www.instagram.com/meny_coin/" className="instagram" target="_blank" rel="noreferrer">
                        <img src="/img/social/instagram-ico.svg" alt="Meny-instagram" />
                    </a>
                </div>
            )}
            {props.youtube && (
                <div>
                    <a href="https://www.youtube.com/@MenyCoin" className="youtube" target="_blank" rel="noreferrer">
                        <img src="/img/social/youtube-ico.svg" alt="Meny-youtube" />
                    </a>
                </div>
            )}
        </List>
    );
}
  
export default SocialList;

const List = styled.div`
    gap: 8px;
    align-items: center;

    a{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        --box-shadow-color: var(--yellow-color-dark);
        box-shadow: 0px -6px 0px 0px var(--box-shadow-color) inset;
        border: 1px solid var(--yellow-color-dark);
        background-color: #fff;
        border-radius: 8px;

        width:42px;
        height:48px;

        &:hover{
            color: #000;
            --box-shadow-color: transparent;
        }

        &:active{
            box-shadow: 0px 4px 6px 0px var(--black-color) inset;
            border-color: var(--black-color);
        }

        img{
            width: 100%;
            max-width: 26px;
            margin-bottom: 6px;
        }
    }
`