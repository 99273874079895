import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PageTemplate from './components/index';
import { LanguageProvider, LanguageContext } from './context/languageContext';
import { translations } from './translations';
import { Helmet } from 'react-helmet';

function App() {
    return (
        <Router>
            <LanguageProvider>
                <LanguageRoutes />
            </LanguageProvider>
        </Router>
    );
}

function LanguageRoutes() {
    const { language } = useContext(LanguageContext);
    const text = translations.metaHead[language];
    useEffect(() => {
        document.documentElement.lang = language;
    }, [language]);

    return (
        <>
            <Helmet>
                <title>{text?.metaTitle || ''}</title>
                <meta name="description" content={text?.metaDescription || ''} />
            </Helmet>
            <Routes>
                <Route exact path="/" element={<PageTemplate className={language} />} />
                <Route path={`/:language/`} element={<PageTemplate className={language} />} />
                <Route path="*" element={<Navigate to={`/`} />} />
            </Routes>
        </>
    );
}

export default App;
