import React, {useContext, useState, useEffect} from 'react';
import { styled } from 'styled-components';
import Btn from '../ui/Btn';
import SocialList from '../ui/SocialList';
import Select from '../ui/Select';
import { LanguageContext } from '../../context/languageContext';
import { langsList } from '../../config'


const HeaderDopComponent = ({setMenuOpen}) => {
    const { language, changeLanguage } = useContext(LanguageContext);
    const [langState, setLangState] = useState(language);

    useEffect(() => {
        setLangState(language)
    }, [language])

    const handleLanguageChange = (e) => {
        changeLanguage(e.value);
        setLangState(e.value)
        setMenuOpen(false);
    };

    return (
        <DopBox className="dop-box f-box">
            <Btn type="link" href="/meny-whitepaper.pdf" target="_blank">Whitepaper</Btn>
            <SocialList telegram x/>
            <div className="lang-box">
                <Select
                    name="language"
                    id="language"
                    options={langsList}
                    value={langsList.find(option => option.value === langState)}
                    isSearchable={false}
                    placeholder={false}
                    closeMenuOnScrolls
                    menuPlacement="auto"
                    onChange={(e) => handleLanguageChange(e)}
                />
            </div>
        </DopBox>
    );
}

export default HeaderDopComponent;

const DopBox = styled.div`
    &.dop-box{
        gap: 8px;
        align-items: center;

        .social-list div:has(.twitter){
            order: 1;
        }

        .lang-box{
            .select__control{
                font-size: 18px;

                .select__indicator{
                    top: -3px;
                }
            }

            .select__menu{
                text-align: center;

                .select__option{
                    padding: 8px;
                }
            }
        }
    
    }
`