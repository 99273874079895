import React, {useState, useContext} from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';


const FaqSectionComponent = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [setRef] = useAnimateOnScroll('active');

    const toggleItem = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const { language } = useContext(LanguageContext);
    const text = translations.faqSect[language];

    return (
        <FaqSection className="sect faq-sect g-box" id="faq">
            <div className="container">
                <div className="rows">
                    <div className="title-box slideUp" ref={setRef}>
                        <h3 className="title sect-title align-center">{text ? parse(text.title) : ''}</h3>
                    </div>
                    <div className="faq-list g-box">
                        {text.items.map((item, index) => (
                            <div className="item slideUp" key={index} ref={setRef}>
                                <div className="ask-box" onClick={() => toggleItem(index)}>
                                    <p>{text ? parse(item.question) : ''}</p>
                                    <div className={`arrow ${activeIndex === index ? 'open' : ''}`}></div>
                                </div>
                                <div className={`answer-box ${activeIndex === index ? 'open' : ''}`}>
                                    {text ? parse(item.answer) : ''}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="horn-box hornRight active">
                <img src="/img/horns/horn-2.svg" alt="horn" />
            </div>
        </FaqSection>
    )
};
  
export default FaqSectionComponent;

const FaqSection = styled.div`
    &.faq-sect{

        .horn-box{
            display: none;
        }
    
        .faq-list{
            max-width:1064px;
            margin: 0 auto;
            grid-gap: 17px;
            width: 100%;

            .item{
                border-radius: 24px;
                background: var(--yellow-color);
                box-shadow: -4px 4px 0px 0px #010101;

                .ask-box{
                    padding: 24px;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 130%;
                    padding-right: 72px;
                    position: relative;
                    cursor: pointer;

                    p{
                        margin-bottom: 0;
                    }

                    .arrow{
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 50%;
                        right: 24px;
                        transform: translateY(-50%);
                        transition: .2s ease;
                        
                        &::before,
                        &::after{
                            content: '';
                            position: absolute;
                            background-color: var(--black-color);
                        }

                        &::before{
                            width:100%;
                            height: 2px;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &::after{
                            width:2px;
                            height: 100%;
                            left: 50%;
                            top: 0;
                            transform: translateX(-50%);
                        }

                        &.open{
                            transform: translateY(-50%) rotate(90deg);
                        }
                    }
                }

                .answer-box{
                    display: none;
                    font-size: 20px;
                    padding: 24px;
                    padding-top: 0;
                    margin-top: -10px;
                    line-height: 133%;

                    &.open{
                        display: block;
                    }
                }
            }
        }
    
    }

    @media (max-width: 992px){
        &.faq-sect{
            .rows{
                grid-gap: 32px;
            }
                
            .faq-list{
                .item{
                    .ask-box{
                        font-size: 28px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px){
        &.faq-sect{
            position: relative;
            padding-top: 100px;

            .faq-list{
                .item{
                    .ask-box{
                        padding: 20px;
                        font-size: 20px;
                        padding-right: 65px;
                    }
                    .answer-box{
                        padding: 20px;
                        padding-top: 0;
                        font-size: 16px;

                        ol, ul {
                            padding-left: 1.5rem;
                        }
                    }
                }
            }           

            .horn-box{
                display: block;
                width: 135px;
                top: 0;
                position: absolute;
                right: 0;
            }
        }
    }
`