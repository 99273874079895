import { useState, useEffect } from 'react';
import { SolanaConnect } from "solana-connect";
import {
    Connection,
    PublicKey,
    Transaction,
    sendAndConfirmTransaction,
    LAMPORTS_PER_SOL,
    SystemProgram,
    Commitment,
    TransactionConfirmationStrategy,
} from "@solana/web3.js";
import {
    SolanaMobileWalletAdapter,
    createDefaultAuthorizationResultCache,
    createDefaultAddressSelector,
    createDefaultWalletNotFoundHandler,
} from "@solana-mobile/wallet-adapter-mobile";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { LedgerWalletAdapter } from "@solana/wallet-adapter-ledger";
// import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
// import { TrustWalletAdapter } from "@solana/wallet-adapter-trust";

const SOLANA_NETWORK = 'https://go.getblock.io/811f8bfd0ebb4412b72fe031c0abe20f';
const WEBSOCKET_URL = 'wss://go.getblock.io/a506ec55afb6451aa367d8f76e85b285';
const connection = new Connection(SOLANA_NETWORK, {commitment: 'processed', wss: WEBSOCKET_URL});
const RECIPIENT_ADDRESS = '4oax9kG2FPHGXKLu5HUwxzLa3bM4D1GWhLWLcV8Wht62';

const solConnect = new SolanaConnect({
    additionalAdapters: [
        new SolflareWalletAdapter(),
        new PhantomWalletAdapter(),
        new LedgerWalletAdapter(),
        // new SlopeWalletAdapter(),
        // new TrustWalletAdapter(),
        /* new SolanaMobileWalletAdapter({
            addressSelector: createDefaultAddressSelector(),
            appIdentity: {
                name: "Supercorp",
                uri: "https://supercorp.app/",
                icon: "icon.png",
            },
            authorizationResultCache: createDefaultAuthorizationResultCache(),
            cluster: "mainnet-beta",
            onWalletNotFound: createDefaultWalletNotFoundHandler(),
        }),*/
    ],
});


export function useSolanaConnect() {
    const [isConnected, setIsConnected] = useState(false);
    const [walletAddress, setWalletAddress] = useState(null);
    const [walletAdapter, setWalletAdapter] = useState(null);
    const [balance, setBalance] = useState(null);

    const handleWalletChange = (adapter) => {
        if (adapter) {
            setIsConnected(true);
            const publicKey = adapter.publicKey?.toString();
            setWalletAddress(publicKey);
            setWalletAdapter(adapter);
        } else {
            setIsConnected(false);
            setWalletAddress(null);
            setWalletAdapter(null);
        }
    };

    const open = () => {
        solConnect.openMenu();
    };

    const fetchBalance = async () => {
        try {
            const publicKey = new PublicKey(walletAddress);
            const balance = await connection.getBalance(publicKey);
            console.log('баланс получен');
            console.log('balance', balance / LAMPORTS_PER_SOL);
            
            setBalance(balance / LAMPORTS_PER_SOL);
        } catch (error) {
            console.error("Ошибка получения баланса:", error);
        }
    };

    const disconnect = () => {
        if (walletAdapter && walletAdapter.disconnect) {
            walletAdapter.disconnect();
        }
        setIsConnected(false);
        setWalletAddress(null);
        setWalletAdapter(null);
        setBalance(null);
    };

    useEffect(() => {
        if (walletAddress) {
            fetchBalance();
        }
    }, [walletAddress]);

    useEffect(() => {
        solConnect.onWalletChange(handleWalletChange);
        solConnect.onVisibilityChange((isOpen) => {
            console.log("Меню кошелька отображается:", isOpen);
        });
    }, []);

    const sendTransaction = async (amount) => {
        try {
            if (!walletAddress || !walletAdapter) {
                return({id: 100, text: "Wallet is not connected"});
            }
            // const confirmationStrategy: TransactionConfirmationStrategy = {
            //     commitment: 'processed', // 'processed', 'confirmed', 'finalized'
            //     // preflightCommitment: 'processed',
            // };
            const fromWallet = new PublicKey(walletAdapter.publicKey);
            const toWallet = new PublicKey(RECIPIENT_ADDRESS);

            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: fromWallet,
                    toPubkey: toWallet,
                    lamports: amount * LAMPORTS_PER_SOL,
                })
            );

            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = fromWallet;

            const signedTransaction = await walletAdapter.signTransaction(transaction);
            const signature = await connection.sendRawTransaction(signedTransaction.serialize());

            // const confirmation = await connection.confirmTransaction(
            //     signature,
            //     confirmationStrategy
            // );
            // console.log("Confirmation:", confirmation);
            // console.log("Транзакция успешна. Подпись:", signature);
            console.log('is Connected1', isConnected);
            if(signedTransaction && signature) {
                console.log("Signed transaction:", signedTransaction);
                console.log("Transaction success", signature);
                disconnect()
                return {id: 200, hash: signedTransaction.recentBlockhash, link: 'https://solscan.io/account/'}
            }
           
        } catch (error) {
            return {id: 101, text: 'Transaction error. Low balance or Solana network not unswer. Try again later'}
        }
    };

    return {
        isConnected,
        walletAddress,
        balance,
        open,
        disconnect,
        sendTransaction,
    };
}
