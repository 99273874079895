import React from 'react';
import Select, { components } from 'react-select';
import { styled } from 'styled-components';

const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
        <path d="M16.5 1L9.35355 8.14645C9.15829 8.34171 8.84171 8.34171 8.64645 8.14645L1.5 0.999999" stroke="#FFCD00" strokeWidth="2" strokeLinecap="round"/>
      </svg>
    </components.DropdownIndicator>
  );
};

const SelectComponent = ({...props}) => {
  return(
      <StyledSelect 
        {...props}
        classNamePrefix="select"
        components={{ DropdownIndicator: CustomDropdownIndicator }}
      />
  )
};

export default SelectComponent;

const StyledSelect = styled(Select)`
    .select__control{
        --box-shadow-color: var(--yellow-color-dark);
        box-shadow: 0px -6px 0px 0px var(--box-shadow-color) inset;
        border: 1px solid var(--yellow-color-dark);
        background-color: #fff;
        border-radius: 8px;
        min-height:48px;
        padding: 8px;
        gap: 4px;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 16px;
        min-width: 93px;
        font-weight: 600;

        &:hover{
          border-color: var(--yellow-color-dark);
        }

        &:focus{
            outline: none;
        }

        .select__value-container{
            padding: 0 0 6px 2px;        
        }

        .select__indicator-separator{
            display: none;
        }

        .select__single-value{
          color: var(--black-color);
          margin: 0;
        }

        .select__indicator{
          padding:0;
          width:24px;
          height:24px;  
          display: flex;
          align-items: center;
          justify-content: center;   
          position: relative;    

          svg{
            width: 18px;
            height: 10px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center;
            transform: translate(-50%, -50%) rotate(0deg);
            transition: .2s ease;
          }
        }

        &.select__control--menu-is-open{
          .select__indicator svg{
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
    }

    .select__menu{
      border-radius: 8px;
      border: 1px solid var(--yellow-color-dark);
      background: #FFF;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: 600;

      .select__menu-list{
        padding: 0;
      }

      .select__menu-list::-webkit-scrollbar{
        width: 3px; 
      }

      .select__menu-list::-webkit-scrollbar-thumb {
        background-color: var(--yellow-color-dark);
        border-radius: 2px;
      }

      .select__option{
        padding: 12px 16px;
        cursor:pointer;
      }

      .select__option.select__option--is-focused{
        background-color: rgba(255, 245, 153, 1);
        color: var(--balck-color);
      }
      .select__option.select__option--is-selected{
        background-color: var(--yellow-color);
        color: var(--balck-color);
      } 
    }

    .option-with-ico{
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 8px;
      align-items: center;

      img{
        max-width:24px;
        width: 100%;
      }
    }
      
`