import React, { useState, useEffect, useContext } from 'react';
import { styled, keyframes } from 'styled-components';
import parse from 'html-react-parser';
import useWindowSize from "../hooks/windowResize"; 
import Btn from '../ui/Btn';
import scrollFunction from '../../utils/scrollFunction';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const HeadSectionComponent = () => {
    const [windowWidth] = useWindowSize();
    const [mobArrow, setMobArrow] = useState(false);

    const { language } = useContext(LanguageContext);
    const text = translations.headSect[language];

    useEffect(() => {
        if(windowWidth < 768){
            setMobArrow(true);
        } else {
            setMobArrow(false);
        }
    }, [windowWidth]);

    return (
        <HeadSection className="sect head-sect g-box with-shadow">
            <div className="container">
                <div className="columns g-box">
                    <div className="info-box g-box">
                        <div className="title-box">
                            <h1 className="title main-title">{text ? parse(text.title) : ''}</h1>
                        </div>
                        <div className="text-block">
                            <div className="text-box">
                                <p>{text ? parse(text.text) : ''}</p>
                            </div>
                            <div className="arrow">
                                <img src={mobArrow ? '/img/sections/head-box/arrow-coin-top-mob.svg' : '/img/sections/head-box/arrow-coin-top.svg'} alt="Meny Coin arrow" />
                            </div>
                        </div>
                        <div className="coin">
                            <img src="/img/coins/coin-1.svg" alt="Meny Coin" />
                        </div>
                    </div>
                    <div className="img-box">
                        <img src="/img/meny/meny-big.svg" alt="Meny" className="meny-anim" />
                    </div>
                    <div className="contract-info g-box">
                        <div className="buttons g-box">
                            <Btn type="button" className="big" onClick={() => scrollFunction('buy-sect')}>{text?.buttonText1 || ''}</Btn>
                            <Btn type="link" href="/meny-whitepaper.pdf" target="_blank" className="white big">{text?.buttonText2 || ''}</Btn>
                        </div>
                    </div>
                </div>
            </div>
        </HeadSection>
    )
};
  
export default HeadSectionComponent;

const coinAnim = keyframes`
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    20% {
        transform: translate(1px, -3px) rotate(2deg);
    }
    40% {
        transform: translate(-1px, -1px) rotate(-2deg);
    }
    60% {
        transform: translate(3px, 1px) rotate(3deg);
    }
    80% {
        transform: translate(-3px, 3px) rotate(-3deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
`;

const menyAnim = keyframes`
    from {
        transform: translate(50px, 0) scale(0.95);
        opacity: 0;
    }

    to {
        transform: translate(-15px, 0) scale(1);
        opacity: 1;
        visibility: visible;
    }
`;

const HeadSection = styled.div`
    &.head-sect{
        padding:150px 0 132px;
        position: relative;

        .meny-anim{
            animation: ${menyAnim} 1s ease-out forwards;
            animation-delay: 1s;
            opacity: 0;
        }

        .container{
            min-height: 530px;
        }

        &::before {
            content: '';
            width: 1440px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-image: url(/img/sections/head-box/pikaso_texttoima.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
            z-index: -1;
        }

        &::after{
            content: '';
            width: 100%;
            height: 194px;
            background-image: url(/img/clouds/clouds.svg);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: center;
            position: absolute;
            bottom: -132px;
            left: 0;
            z-index: 3;

        }

        .columns{
            grid-template-columns: 1fr 1fr;
            grid-gap: 80px 0;
        }

        .title-box{
            z-index: 2;
        }

        .text-box{        
            border-radius: 16px;
            padding: 24px;
            background-color: var(--yellow-color);
            box-shadow: -4px 4px 0px 0px var(--black-color);
            z-index: 1;
        }

        .text-block{
            position: relative;
            z-index: 1;
        }

        .img-box{
            height:100%;
            position: relative;
            grid-row-start: span 2;

            img{
                width: 817px;
                position: absolute;
                top: 0;
                left: 0;
                max-width: initial;
                z-index: 2;
                transform: translateX(-15px);
            }
        }

        .contract-info{
            width:100%;
            max-width: 510px;
            grid-gap: 20px;
            z-index: 1;

            label{
                font-size: 15px;
                font-style: normal;
                font-weight: 800;
                margin-bottom: 8px;
                width: 100%;
            }

            input{
                width:100%;
                padding-right: 62px;
            }

            .buttons{
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 16px;

                .btn{
                    width:100%;
                    padding: 12px 20px 17px;
                }
            }
        }

        .info-box{
            position: relative;
        }

        .coin{
            position: absolute;
            top: 0px;
            right: 20px;
            animation: ${coinAnim} 5s ease-in-out infinite;
            transform: translate3d(0, 0, 0);
            width:100%;
            max-width:85px;
            z-index: 1;

            img{
                width:100%;
            }
        }

        .arrow{
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(52px, -240px);
            z-index: -1;

            img{
                width:100%;
                max-width: 174px;
            }
        }
    }

    @media (min-width: 1190px){
        .fr &.head-sect,
        .pt &.head-sect,
        .es &.head-sect{
            .btn.big{
                font-size: 22px;
            }

            .contract-info{
                max-width: 570px;
            }
        }

        .de &.head-sect,
        .it &.head-sect,
        .es &.head-sect,
        .id &.head-sect{
            .title-box{
                margin-top: 40px;

                .title.main-title{
                    font-size:65px;
                }
            }
        }
    
        .pt &.head-sect,
        .hu &.head-sect{
            .title-box{
                .title.main-title{
                    font-size:74px;
                }
            }
        }

        .cz &.head-sect .title-box,
        .hu &.head-sect .title-box,
        .pl &.head-sect .title-box,
        .ro &.head-sect .title-box,
        .sk &.head-sect .title-box,
        .vi &.head-sect .title-box,
        .tr &.head-sect .title-box,
        .el &.head-sect .title-box,
        .ja &.head-sect .title-box,
        .ko &.head-sect .title-box{
            max-width: 520px;
        }

        .zh &.head-sect .title-box,
        .zhHant &.head-sect .title-box{
            max-width: 450px;
        }
    
    }

    @media (min-width: 992px) and (max-width: 1190px){
        &.head-sect{
            .title-box .title.main-title{
                font-size: 65px;
            }
        }

        .de &.head-sect .title-box .title.main-title,
        .it &.head-sect .title-box .title.main-title,
        .es &.head-sect .title-box .title.main-title,
        .id &.head-sect .title-box .title.main-title,
        .ar &.head-sect .title-box .title.main-title,
        .el &.head-sect .title-box .title.main-title{
            font-size: 54px;
        }

        .cz &.head-sect .title-box,
        .hu &.head-sect .title-box,
        .pl &.head-sect .title-box,
        .ro &.head-sect .title-box,
        .sk &.head-sect .title-box,
        .vi &.head-sect .title-box,
        .tr &.head-sect .title-box,
        .ja &.head-sect .title-box,
        .ko &.head-sect .title-box,
        .de &.head-sect .title-box,
        .it &.head-sect .title-box,
        .es &.head-sect .title-box,
        .id &.head-sect .title-box,
        .ar &.head-sect .title-box,
        .el &.head-sect .title-box{
            max-width: 430px;
        }

        .fr &.head-sect,
        .pt &.head-sect,{
            .contract-info{
                max-width: 300px;

                .buttons {
                    grid-template-columns: 1fr;

                    .btn.big{
                        padding: 12px 20px 17px;
                        font-size: 22px;
                    }
                }
            }
        }

        .zh &.head-sect .title-box,
        .zhHant &.head-sect .title-box{
            max-width: 350px;
        }
    }

    @media(min-width: 768px) and (max-width: 992px){
        &.head-sect{
            .title-box .title.main-title{
                font-size: 55px;
            }
        }

        .nl &.head-sect{
            .title-box .title.main-title{
                font-size: 58px;
            }
        }

        .de &.head-sect .title-box .title.main-title,
        .it &.head-sect .title-box .title.main-title,
        .es &.head-sect .title-box .title.main-title,
        .id &.head-sect .title-box .title.main-title,
        .ar &.head-sect .title-box .title.main-title,
        .el &.head-sect .title-box .title.main-title{
            font-size: 44px;
        }

        .de &.head-sect .title-box,
        .it &.head-sect .title-box,
        .es &.head-sect .title-box,
        .id &.head-sect .title-box,
        .ar &.head-sect .title-box,
        .el &.head-sect .title-box{
            margin-top: 40px;
        }

        .cz &.head-sect .title-box,
        .hu &.head-sect .title-box,
        .pl &.head-sect .title-box,
        .ro &.head-sect .title-box,
        .sk &.head-sect .title-box,
        .vi &.head-sect .title-box,
        .tr &.head-sect .title-box,
        .el &.head-sect .title-box,
        .ja &.head-sect .title-box,
        .ko &.head-sect .title-box,
        .de &.head-sect .title-box,
        .it &.head-sect .title-box,
        .es &.head-sect .title-box,
        .id &.head-sect .title-box,
        .ar &.head-sect .title-box{
            max-width: 350px;
        }

        .fr &.head-sect,
        .pt &.head-sect,
        .es &.head-sect,
        .el &.head-sect,
        .ro &.head-sect,
        .ja &.head-sect{
            .contract-info{
                max-width: 300px;

                .buttons {
                    grid-template-columns: 1fr;

                    .btn.big{
                        padding: 12px 20px 17px;
                        font-size: 22px;
                    }
                }
            }
        }

        .de &.head-sect{
            .btn.big{
                padding: 12px 20px 17px;
            }
        }

        .zh &.head-sect .title-box,
        .zhHant &.head-sect .title-box{
            max-width: 250px;
        }
    }  

    @media (max-width: 1160px){
        &.head-sect{
            .yellow-text-box{
                font-size: 22px;
            }

            .arrow{
                transform: translate(54px, -208px);
            }
        }
    } 

    @media (max-width: 992px){
        &.head-sect{
            .columns{
                grid-template-columns: 1fr 0.85fr;
            }

            .coin{
                right: 15px;
            }

            .img-box img{
                width: 685px;
            }
            .arrow {
                transform: translate(20px, -180px);
            }
        }
    } 

    @media (max-width: 850px){
        &.head-sect{
            .columns{
                grid-template-columns: 1fr 0.7fr;
            }
        }

    }

    @media (max-width: 768px){
        &.head-sect{
            padding: 110px 0 72px;
            background: radial-gradient(266.18% 119.52% at 0% 100%, #A7D4EB 39.11%, rgba(167, 212, 235, 0.00) 63.88%, rgba(167, 212, 235, 0.02) 96.82%);

            &::before {
                background-position: top -37px left 153px;
                background-size: auto;
            }

            &::after{
                height: 60px;
                bottom: -10px;
            }

            .info-box{
                grid-gap: 16px;
            }

            .columns {
                grid-template-columns: 1fr;
                grid-gap: 16px;
            }  

            .title-box .title.main-title{
                text-align: center;
            }

            .text-box{            
                padding: 12px;
            }

            .img-box {
                grid-row-start: initial;
                text-align: center;

                img{
                    position: relative;
                    max-width: 385px;
                    right: initial;
                    top: initial;
                    margin-right: -60px;
                }
            }

            .coin{
                top: 81px;
                z-index: 2;
                right: -12px;
                max-width: 56px;
            }

            .arrow{
                transform: translate(10px, -130px);
            }
            
            .contract-info{
                label{
                    text-align: center;
                }

                .buttons {
                    grid-template-columns: 1fr;
                    grid-gap: 8px;
                }
            }
        }

        .de &.head-sect,
        .it &.head-sect,
        .id &.head-sect{
            .title-box{
                max-width: 320px;
                margin: 25px auto 0;

                .title.main-title{
                    font-size: 38px;
                }
            }
        }

        .cz &.head-sect .title-box,
        .hu &.head-sect .title-box,
        .pl &.head-sect .title-box,
        .ro &.head-sect .title-box,
        .sk &.head-sect .title-box,
        .vi &.head-sect .title-box,
        .tr &.head-sect .title-box,
        .el &.head-sect .title-box,
        .ja &.head-sect .title-box,
        .ko &.head-sect .title-box{
            max-width: 320px;
            margin: 0 auto;
        }

        .cz &.head-sect .title-box .title.main-title,
        .pl &.head-sect .title-box .title.main-title,
        .ro &.head-sect .title-box .title.main-title,
        .sk &.head-sect .title-box .title.main-title,
        .vi &.head-sect .title-box .title.main-title{
            font-size: 48px;
        }

        .ar &.head-sect .title-box .title.main-title,
        .el &.head-sect .title-box .title.main-title,
        .hu &.head-sect .title-box .title.main-title{
            font-size: 44px;
        }

        .zh &.head-sect .title-box,
        .zhHant &.head-sect .title-box{
            max-width: 250px;
            margin: 0 auto;
        }
    }

    @media (max-width: 380px) {
        &.head-sect{
            &::before {
                background-position: top -37px left 145px;
                background-size: auto;
            }
        }
    }

    @media (max-width: 374px){
        &.head-sect{
            .img-box{
                img{
                    max-width:110%;
                }
            }
        }
    }

    @media (max-width: 365px){
        &.head-sect{
            .coin{
                top: 70px;
            }
        }

        .cz &.head-sect .title-box,
        .hu &.head-sect .title-box,
        .pl &.head-sect .title-box,
        .ro &.head-sect .title-box,
        .sk &.head-sect .title-box,
        .vi &.head-sect .title-box,
        .tr &.head-sect .title-box,
        .el &.head-sect .title-box,
        .ja &.head-sect .title-box,
        .ko &.head-sect .title-box{
            max-width: 290px;
        }

        .zh &.head-sect .title-box,
        .zhHant &.head-sect .title-box{
            max-width: 200px;
        }
    }
`

