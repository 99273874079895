import axios from "axios"
import { GET_BINANCE_TICKER } from "../constants"

export const GetBinanceTicker = async (symbol) => {

    try {
        const res = await axios.get(GET_BINANCE_TICKER(symbol))
        return res
    } 
    catch (error) {
        if (error.response?.status === 422) {
            return {status: 422, resError: error.response.data}
        } else if (error.response?.status === 403) {
            return {status: 403, resError: error.response.data}
        } else if (error.request) {
            return {status: 404}
        } 
        else if (error.response?.status === 405) {
            return {status: 405}
        }
        else {
            return {status: 505}
        }
    }
}