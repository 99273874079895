import React, { forwardRef } from 'react';
import { styled } from 'styled-components';

const InputComponent = forwardRef(({htmlType, children, ...props}, ref) => {
  switch (htmlType) {
    case 'textarea':
      return <StyledInput><textarea {...props} ref={ref}>{children}</textarea></StyledInput>;
    default:
      return (
        <StyledInput className={props.icon && 'with-ico'}>
          <input {...props} ref={ref}/>
          {props.icon && <img src={props.icon} alt="" className="ico "/>}
        </StyledInput>
      );
  }
});

export default InputComponent;

const StyledInput = styled.div`   
    input,
    textarea{
      padding: 15px 16px;
      min-height: 56px;
      border-radius: 10px;
      border: 1px solid var(--yellow-color);
      background: #FFF;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
    }

    input:focus,
    textarea:focus{
      outline: none;
    }

    input::placeholder{
      color:rgba(84, 85, 89, 1);
    }

    input:hover::placeholder{
      color: var(--black-color);
    }

    input:focus::placeholder{
      color: transparent;
    }

    textarea{
      overflow: hidden;;
    }

    &.with-ico{
      position: relative;

      input{
        padding-right: 56px;
      }

      .ico{
        width:24px;
        position: absolute;
        right:16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
`