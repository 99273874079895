import React, { useContext } from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const ListingSectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');

    const { language } = useContext(LanguageContext);
    const text = translations.listingSect[language];

    return (
        <ListingSection className="sect listing g-box">
            <div className="container">
                <div className="rows">
                    <div className="title-box slideUp" ref={setRef}>
                        <h3 className="title sect-title align-center">{text ? parse(text.title) : ''}</h3>
                    </div>
                    <div className="listing-list g-box">
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="img-box">
                                <a href="https://www.binance.com/" target="_blank">
                                    <img src="/img/listing/binance.svg" alt="Binance" />
                                </a>
                            </div>
                            <div className="cloud">
                                <img src="/img/clouds/clouds-small-1.svg" alt="Clouds" />
                            </div>
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="img-box">
                                <a href="https://www.bybit.com/" target="_blank">
                                    <img src="/img/listing/bybit.svg" alt="Bybit" />
                                </a>
                            </div>
                            <div className="cloud">
                                <img src="/img/clouds/clouds-small-2.svg" alt="Clouds" />
                            </div>
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="img-box">
                                <a href="https://www.okx.com/" target="_blank">
                                    <img src="/img/listing/okx.svg" alt="Okx" />
                                </a>
                            </div>
                            <div className="cloud">
                                <img src="/img/clouds/clouds-small-2.svg" alt="Clouds" />
                            </div>
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="img-box">
                                <a href="https://www.gate.io/" target="_blank">
                                    <img src="/img/listing/gate.svg" alt="Gate" />
                                </a>
                            </div>
                            <div className="cloud">
                                <img src="/img/clouds/clouds-small-1.svg" alt="Clouds" />
                            </div>
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="img-box">
                                <a href="https://www.mexc.com/" target="_blank">
                                    <img src="/img/listing/mexc.svg" alt="Mexc" />
                                </a>
                            </div>
                            <div className="cloud">
                                <img src="/img/clouds/clouds-small-1.svg" alt="Clouds" />
                            </div>
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="img-box">
                                <a href="https://bingx.com/" target="_blank">
                                    <img src="/img/listing/bingx.svg" alt="Bingx" />
                                </a>
                            </div>
                            <div className="cloud">
                                <img src="/img/clouds/clouds-small-2.svg" alt="Clouds" />
                            </div>
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="img-box">
                                <a href="https://www.htx.com/" target="_blank">
                                    <img src="/img/listing/htx.svg" alt="Htx" />
                                </a>
                            </div>
                            <div className="cloud">
                                <img src="/img/clouds/clouds-small-2.svg" alt="Clouds" />
                            </div>
                        </div>
                        <div className="item g-box slideUp" ref={setRef}>
                            <div className="img-box">
                                <a href="https://www.bitget.com/" target="_blank">
                                    <img src="/img/listing/bitget.svg" alt="Bitget" />
                                </a>
                            </div>
                            <div className="cloud">
                                <img src="/img/clouds/clouds-small-1.svg" alt="Clouds" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="horn-box hornLeft" ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
        </ListingSection>
    )
};
  
export default ListingSectionComponent;

const ListingSection = styled.div`
    &.listing{
        background-color: var(--orange-light-color);
        padding-top: 203px;
        position: relative;

        .rows{
            grid-gap: 48px; 
        }

        .horn-box{
            width: 280px;
            position: absolute;
            top: 90px;
            left: 0;

            img{
                transform: scale(-1, 1);
            }
        }

        .listing-list{
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 16px;

            .item{
                grid-gap: 12px;

                .img-box{
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    min-height: 52px;
                }

                &:nth-child(4n + 3) .cloud,
                &:nth-child(4n + 4) .cloud{
                    transform: scale(-1, 1);
                }
            }
        }

        .id &.listing{
            .title-box{
                max-width: 600px;
                margin: 0 auto;
            }
        }
    }

    @media (max-width: 1100px){
        &.listing{

            .horn-box{
                width: 230px;
            }
        }
    }

    @media (max-width: 992px){
        &.listing{

            .listing-list{
                grid-template-columns: repeat(3, 1fr);
            }   
        }

        .de &.listing,
        .hu &.listing,
        .sk &.listing,
        .id &.listing,
        .nl &.listing{
            .horn-box{
                top: 55px;
            }
        }
    }

    @media (max-width: 768px){
        &.listing{
            padding-top: 90px;

            .rows{
                grid-gap: 32px;
            }

            .horn-box{
                display: none;
            }

            .listing-list{
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 16px 8px;

                .item{
                    grid-gap: 7px;

                    .img-box{
                        min-height: initial;
                        
                        img{
                            max-height: 30px;
                        }
                    }
                }
            }   
        }
    
    }
`