import { useEffect } from 'react';

const ChatWidget = () => {
  useEffect(() => {
    // Создание элемента <script>
    const script = document.createElement('script');
    
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://embed.tawk.to/66d2f9b4ea492f34bc0c2615/1i6k1urdi';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Вставка скрипта перед первым <script> в DOM
    const script0 = document.getElementsByTagName('script')[0];
    script0.parentNode.insertBefore(script, script0);

    // Очистка (удаление скрипта) при размонтировании компонента
    return () => {
      script0.parentNode.removeChild(script);
    };
  }, []); // Пустой массив означает, что эффект выполняется только при монтировании и размонтировании

  return null; // Компонент не рендерит ничего
};

export default ChatWidget;