import React, {useEffect, useState, useRef, useContext} from 'react';
import { styled } from 'styled-components';
import useWindowSize from "../hooks/windowResize";
import { LanguageContext } from '../../context/languageContext';

const Tooltip = ({tooltipText, children}) => {
    const [windowWidth] = useWindowSize();
    const [tooltipWidth, setTooltipWidth] = useState('');
    const [translateXY, setTranslateXY] = useState('100%');
    const tooltipRef = useRef(null);
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        if (windowWidth < 430 && tooltipRef.current) {
            setTooltipWidth(windowWidth - 17);
            const rect = tooltipRef.current.getBoundingClientRect();
            const shiftX = rect.left + 15;
            const translateX = `calc(100% - ${shiftX}px)`;
            const transforXY = `translate(${translateX}, calc(-100% - 8px))`;
            setTranslateXY(transforXY);
        } else {
            setTooltipWidth('');
            setTranslateXY('');
        }
    }, [windowWidth, language]);
    return (
        <TooltipBox className="tooltip-box">
            <div className="target" ref={tooltipRef}>{children}</div>
            <div 
                className="tooltip"
                style={{
                    width: tooltipWidth ? tooltipWidth + 'px' : '',
                    transform: translateXY
                }}
            >
                {tooltipText}
            </div>
        </TooltipBox>
    );
}
  
export default Tooltip;

const TooltipBox = styled.div`
    &.tooltip-box{
        position: relative;
        display: inline-block;

        .target{
            cursor: pointer;
        }

        .i-btn{
            width: 24px;
            height: 24px;
            background-image: url(/img/tooltip-ico.svg);
            display: block;
            background-repeat: no-repeat;
            background-size: 24px;

            &:hover{
                background-image: url(/img/tooltip-hover-ico.svg);
            }
        }

        .tooltip{
            position: absolute;
            top: 0;
            transform: translate(102%, -102%);
            right: 0;
            padding: 16px 24px 16px 24px;
            border-radius: 16px 16px 16px 0px;
            color: #fff;
            background-color: #006294;
            box-shadow: -4px 4px 0px 0px #010101;
            font-weight: 500;
            font-size: 15px;
            opacity: 0;
            visibility: hidden;
            transition: .2s ease opacity, .2s ease visibility;
            z-index: 4;
        }

        &:hover .tooltip{
            opacity: 1;
            visibility: visible;
        }
    }
`