import React, {useContext} from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const MenynomicsSectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');

    const { language } = useContext(LanguageContext);
    const text = translations.menyNomicsSect[language];

    return (
        <MenynomicsSection className="sect menynomics g-box" id="menynomics">
            <div className="container">
                <div className="rows">
                    <div className="title-box align-center slideUp" ref={setRef}>
                        <h3 className="title sect-title">{text ? parse(text.title) : ''}</h3>
                        <p><b>{text ? parse(text.text) : ''}</b></p>
                    </div>
                    <div className="nomics-box f-box">
                        <div className="img-box">
                            <img src="/img/sections/menynomics/menynomics-img.svg" alt="Menynomics" />
                        </div>
                        <div className="info-item airdrop slideUp" ref={setRef}>
                            <p>{text ? parse(text.airDrop) : ''}</p>
                        </div>
                        <div className="info-item marketing slideUp" ref={setRef}>
                            <p>{text ? parse(text.marketing) : ''}</p>
                        </div>
                        <div className="info-item presale slideUp" ref={setRef}>
                            <p>{text ? parse(text.presale) : ''}</p>
                        </div>
                        <div className="info-item cex slideUp" ref={setRef}>
                            <p>{text ? parse(text.cex) : ''}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="horn-box hornLeft" ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
        </MenynomicsSection>
    )
};
  
export default MenynomicsSectionComponent;

const MenynomicsSection = styled.div`
    &.menynomics{
        position: relative;
        padding-bottom: 120px;

        .horn-box{
            content: '';
            width: 496px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;

            img{
                transform: scale(-1, 1);
            }
        }

        .rows{
            grid-gap: 100px;
        }

        .nomics-box{
            position: relative;
            display: block;
            margin: 0 auto;
            max-width: 624px;

            .info-item{
                padding: 24px;
                border-radius: 16px;
                background-color: var(--orange-light-color);
                box-shadow: -4px 4px 0px 0px #010101;
                font-size: 24px;
                font-weight: 700;
                line-height: 133%;
                display: inline-flex;
                align-items: center;
                position: absolute;

                &.airdrop{
                    top: -60px;
                    right: -70px;
                }

                &.marketing{
                    top: 176px;
                    right: -270px;
                }

                &.presale{
                    bottom: -20px;
                    right: -275px;
                }

                &.cex{
                    top: 262px;
                    left: -320px;

                    br{
                        display: none;
                    }
                }
            }
        }
    
    
    }

    @media (min-width: 992px) and (max-width: 1480px){
        .fr &.menynomics{
            .horn-box{
                left: -100px;
            }
        }
    }

    @media (max-width: 1370px){
        &.menynomics{
            .horn-box{
                width: 365px;
            }
        }
    }

    @media (max-width: 1300px){
        &.menynomics{
            .nomics-box{
                max-width:455px;

                .info-item{
                    padding:16px;
                    font-size: 20px;

                    &.cex {
                        top: 192px;
                        left: -260px;
                    }

                    &.presale{
                        bottom: -15px;
                        right: -250px;
                    }

                    &.marketing{
                        top: 126px;
                        right: -245px;
                    }
                }
            }
        }
    }

    @media (max-width: 992px){
        &.menynomics{
            .horn-box{
                width: 200px;
            }

            .nomics-box{
                max-width:455px;

                .info-item{
                    padding:16px;
                    font-size: 20px;

                    &.airdrop{
                        top: -45px;
                        right: -50px;
                    }

                    &.cex {
                        left: -100px;
                    }

                    &.marketing{
                        right: -130px;
                    }

                    &.presale {
                        bottom: 30px;
                        right: -130px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px){
        &.menynomics{
            padding-bottom: 50px;

            .rows{
                grid-gap: 53px;
            }

            .horn-box{
                width: 112px;
                top: -60px;
            }

            .nomics-box {
                max-width: 200px;

                .info-item {
                    padding: 8px;
                    font-size: 15px;

                    &.airdrop {
                        top: -20px;
                        right: -36px;
                    }

                    &.marketing {
                        top: 65px;
                        right:-75px;
                    }

                    &.presale {
                        bottom: 0;
                        right: -72px;
                        max-width: 125px;
                    }

                    &.cex {
                        left: -68px;
                        max-width: 128px;
                        top: 45px;

                        br{
                            display: block;
                        }
                    }
                }
            }
        }
    }  
        
    @media (max-width: 364px){
        &.menynomics{

            .nomics-box {
                .info-item {
                    &.marketing {
                        transform: translateX(55px);
                    }

                    &.presale {
                        transform: translateX(55px);
                    }

                    &.cex {
                        transform: translateX(-40px);
                    }
                }
            }
        }
    } 
`